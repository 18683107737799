import { useTranslations } from './useTranslations'
import { useCallback } from 'react'
import _get from 'lodash/get'

type TranslateFunc = (key: string, params?: Record<string, unknown>) => string

// TODO use library for translations D21-29695
export const useTranslation = (): TranslateFunc => {
  const translations = useTranslations()
  const lang = 'en'

  return useCallback(
    (key: string, params?: Record<string, unknown>) => {
      if (!translations.data) return ''
      let translation = _get(translations.data[lang], key) as string
      Object.keys(params ?? {}).forEach((paramKey) => {
        translation = translation?.replaceAll(`{{${paramKey}}}`, params?.[paramKey] as string)
      })

      return translation
    },
    [lang, translations.data]
  )
}
