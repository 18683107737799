import axios from 'axios'
import { addAuthInterceptors } from '@dentalux/security'
import qs from 'query-string'

export const coreJarvisClient = axios.create({
  baseURL: `${window.env.REACT_APP_CORE_APP_ENDPOINT}/api/jarvis`,
  withCredentials: true,
})

export const coreClient = axios.create({
  baseURL: `${window.env.REACT_APP_CORE_APP_ENDPOINT}`,
  withCredentials: true,
})

export const itOpsClient = axios.create({
  baseURL: `${window.env.REACT_APP_CORE_APP_ENDPOINT}/api/v1`,
  withCredentials: true,
})

export const employeeClient = axios.create({
  baseURL: `${window.env.REACT_APP_EMPLOYEE_ENDPOINT}/api`,
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params),
})

export const monitoringClient = axios.create({
  baseURL: `${window.env.REACT_APP_MONITORING_ENDPOINT}/api`,
})

export const echoNetClient = axios.create({
  baseURL: `${window.env.REACT_APP_ECHO_NET_ENDPOINT}/api`,
})

export const passengerClient = axios.create({
  baseURL: `${window.env.REACT_APP_PASSENGER_ENDPOINT}/api`,
  withCredentials: true,
})

export const dispacciClient = axios.create({
  baseURL: `${window.env.REACT_APP_DISPACCI_ENDPOINT}/dispacci/api`,
  withCredentials: true,
})

export const patientClient = axios.create({
  baseURL: `${window.env.REACT_APP_PATIENT_ENDPOINT}/api`,
  withCredentials: true,
})

export const anaClient = axios.create({
  baseURL: `${window.env.REACT_APP_ANA_ENDPOINT}/api`,
  withCredentials: true,
})

export const recommendationClient = axios.create({
  baseURL: `${window.env.REACT_APP_RECOMMENDATION_ENDPOINT}/api`,
  withCredentials: true,
})

export const clinicClient = axios.create({
  baseURL: `${window.env.REACT_APP_CLINIC_ENDPOINT}/api`,
  withCredentials: true,
})

export const hadesClient = axios.create({
  baseURL: `${window.env.REACT_APP_HADES_ENDPOINT}/api`,
  withCredentials: true,
})

export const promsClient = axios.create({
  baseURL: `${window.env.REACT_APP_PROMS_ENDPOINT ?? ''}/api`,
  withCredentials: true,
})

export const wondermedsClient = axios.create({
  baseURL: `${window.env.REACT_APP_WONDERMEDS_ENDPOINT}/api`,
  withCredentials: true,
})

export const medicalRecordClient = axios.create({
  baseURL: `${window.env.REACT_APP_MEDICAL_RECORDS_ENDPOINT}/api`,
  withCredentials: true,
})

export const docCirrusAdapterClient = axios.create({
  baseURL: `${window.env.REACT_APP_DOC_CIRRUS_ADAPTER_ENDPOINT}/api`,
  withCredentials: true,
})

export const operatorClient = (() => {
  return axios.create({
    baseURL: `${window.env.REACT_APP_OPERATOR_URL}/api`,
    withCredentials: true,
  })
})()

export const gapFillerClient = (() => {
  return axios.create({
    baseURL: `${window.env.REACT_APP_GAP_FILLER_URL}`,
    withCredentials: true,
  })
})()

addAuthInterceptors(coreJarvisClient)
addAuthInterceptors(coreClient)
addAuthInterceptors(monitoringClient)
addAuthInterceptors(echoNetClient)
addAuthInterceptors(passengerClient)
addAuthInterceptors(dispacciClient)
addAuthInterceptors(employeeClient)
addAuthInterceptors(operatorClient)
addAuthInterceptors(patientClient)
addAuthInterceptors(anaClient)
addAuthInterceptors(recommendationClient)
addAuthInterceptors(clinicClient)
addAuthInterceptors(hadesClient)
addAuthInterceptors(promsClient)
addAuthInterceptors(docCirrusAdapterClient)
addAuthInterceptors(itOpsClient)
addAuthInterceptors(gapFillerClient)
addAuthInterceptors(medicalRecordClient)
