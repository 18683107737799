import { createTheme } from '@mui/material'
import theme from './index'
import { ThemeOptions } from '@mui/material/styles/createTheme'
import colors from './colors'

const redesignTheme: ThemeOptions = {
  palette: {
    text: {
      primary: colors.jarvisDark,
    },
  },
  typography: {
    h1: {
      fontSize: '48px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      letterSpacing: '0.15px',
    },
    h3: {
      fontSize: '18px',
      fontWeight: '500',
      lineHeight: '23px',
      letterSpacing: '0.02em',
      textAlign: 'left',
    },
    subtitle2: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '22px',
      letterSpacing: '0.5px',
      textAlign: 'left',
    },
    caption: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: colors.gray[800],
    },
  },

  components: {
    MuiSelect: {
      defaultProps: {
        /**
         * Fixes the issue with the page shift when the select is opened
         * MUI by default opens dropdowns in a "modal" mode, which removes all scrollbars from the page
         * - it adds "overflow: hidden, padding-right: 15px" to the body.
         * Because of how the redesigned Clinic page is structured, this causes the page to shift when the dropdown is opened
         * (navigation panel is positioned absolutely with "right: {XX}" prop and the body padding shifts it)
         * I don't want to change the structure of the page just to satisfy MUI dropdowns, so I'm disabling this behavior
         *
         * Warning: this will make the page scrollable when the dropdown is opened, but it's ok for our use case
         * See more: https://github.com/mui/material-ui/issues/17353#issuecomment-1698096612
         */

        MenuProps: {
          disableScrollLock: true,
          marginThreshold: null,
        },
      },
    },

    MuiButton: {
      variants: [
        {
          props: {
            variant: 'outlined',
          },
          style: {
            background: colors.white,
          },
        },
      ],

      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },

    MuiFormControlLabel: {
      variants: [
        {
          props: { labelPlacement: 'end' },
          style: {
            alignItems: 'center',
            flex: 1,
          },
        },
      ],
    },
    MuiFilledInput: {
      styleOverrides: {
        underline: {
          '&&&:before': {
            borderBottom: 'none',
          },
          '&&:after': {
            borderBottom: 'none',
          },
        },
        root: {
          '&:hover': {
            borderColor: colors.gray['300'],
            background: colors.white,
          },
          '&:focus-within': {
            borderColor: colors.primary,
            background: colors.white,
          },
          background: colors.white,
          border: `1px solid ${colors.white}`,
          borderRadius: '8px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: colors.white,
        },
      },
    },

    MuiTextField: {
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            borderRadius: '8px',
          },
        },
      ],
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 0,

          paddingBottom: '16px',
          marginBottom: '8px',
          borderBottom: `1px solid ${colors.gray[600]}`,
        },

        title: {
          color: colors.jarvisDark,
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '28px',
          letterSpacing: '0.15px',
        },
        subheader: {
          color: colors.jarvisDark,
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '22px',
          letterSpacing: '0.5px',
          marginTop: '4px',
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          background: colors.jarvisFlatBackground,
          padding: '24px',
          borderRadius: '28px',
          boxShadow: 'none',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.gray[600],
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '22px',
          letterSpacing: '0.5px',

          '&.Mui-expanded': {
            borderBottom: `1px solid ${colors.gray[400]}`,
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '8px !important',
          boxShadow: 'none',
          borderBottom: `0.5px solid ${colors.gray[400]}`,

          '&:before': {
            opacity: 0,
          },

          '&.Mui-expanded': {
            marginTop: 0,
          },
        },
      },
    },
  },
}

export default createTheme(theme, redesignTheme)
