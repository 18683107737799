import React, { Suspense, useCallback, useMemo, useState } from 'react'
import { Redirect, Switch, useLocation } from 'react-router-dom'
import { History } from 'history'
import { ProtectedRoute } from '@dentalux/security'
import { CssBaseline } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import clsx from 'clsx'
import { useQuery, UseQueryResult } from 'react-query'

import AppDrawer, { drawerWidth, NavItem } from './AppDrawer'
import AppBar from './AppBar'

import { getClinicsAndDevices } from '../services/api/clinics'
import routes from '../routes'
import LoadingState from './LoadingState'
import { Device, View } from '../types'
import { getDevices } from '../services/api/devices'
import { useTranslation } from '../hooks/useTranslation'
import redesign from '../theme/redesign'
import { ConditionalTheme } from './ConditionalTheme'

interface Props {
  history: History
  name: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    flexGrow: 1,
    marginTop: 42,
    padding: theme.spacing(3),
    maxWidth: '100%',

    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  redesign: {
    padding: 0,
    paddingTop: theme.spacing(3),
  },
  contentShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

const AppShell = (props: Props) => {
  const t = useTranslation()
  const classes = useStyles()
  const clinics = useQuery('clinics', () => getClinicsAndDevices())
  const [open, setOpen] = useState(true)
  const { pathname } = useLocation()
  const isRedesign = pathname.includes('redesign')

  const handleToggleDrawer = useCallback(() => {
    setOpen((open) => !open)
  }, [setOpen])

  const loading = (): React.ReactElement => <LoadingState />

  const devices: UseQueryResult<Device[]> = useQuery('devices', () => getDevices())

  const clinicsNavbarItems = useMemo(() => {
    return (
      clinics.data?.map((clinic) => {
        const children =
          [
            {
              name: 'Settings',
              url: `#/clinics/${clinic.referenceId}`,
              icon: 'fa fa-clinic-medical',
              view: View.JARVIS_CLINIC,
            },
            {
              name: 'Clinic Treatments',
              url: `#/clinics/${clinic.referenceId}/clinic-treatments`,
              icon: 'fa fa-tooth',
              view: View.JARVIS_CLINIC,
            },
            {
              name: 'Treatment Bundles',
              url: `#/clinics/${clinic.referenceId}/treatments`,
              icon: 'fa fa-tooth',
              view: View.JARVIS_CLINIC,
            },
            {
              name: 'Treatment Groups',
              url: `#/clinics/${clinic.referenceId}/treatment-types`,
              icon: 'fa fa-tooth',
              view: View.JARVIS_CLINIC,
            },
            {
              name: 'Employees',
              url: `#/clinics/${clinic.referenceId}/employees`,
              icon: 'fa fa-user-md',
              view: View.JARVIS_CLINIC,
            },
            {
              name: 'Appointments',
              url: `#/clinics/${clinic.referenceId}/appointments`,
              icon: 'fa fa-calendar-check',
              view: View.JARVIS_AVAILY_BOOKING,
            },
            {
              name: 'Rooms',
              url: `#/clinics/${clinic.referenceId}/rooms`,
              icon: 'fa fa-clinic-medical',
              view: View.JARVIS_CLINIC,
            },
            {
              name: 'Phone setup',
              url: `#/clinics/${clinic.referenceId}/phone-setup`,
              icon: 'fa fa-voicemail',
              view: View.JARVIS_CLINIC,
            },
            {
              name: t('jarvis.nav.clinic.settings'),
              url: `#/clinics-redesign/${clinic.referenceId}`,
              icon: 'fa fa-house',
              view: View.JARVIS_CLINIC,
            },
            // {
            //   name: 'Wondermeds Config',
            //   url: `#/wondermeds/${clinic.referenceId}/config`,
            //   icon: 'fa fa-icons',
            //   view: View.WONDERMEDS_CLINIC_CONFIGURATION
            // },
            // {
            //   name: 'Wondermeds Cluster-Information',
            //   url: `#/wondermeds/${clinic.referenceId}/cluster-information`,
            //   icon: 'fa fa-icons',
            //   view: View.WONDERMEDS_CLUSTER_INFORMATION
            // }
          ] || []

        if (clinic.deviceIds !== undefined && clinic.deviceIds.length > 0) {
          children.push({
            name: 'Environments',
            url: `#/clinics/${clinic.referenceId}/environments`,
            icon: 'fa fa-code',
            view: View.JARVIS_ECHO_NET,
          })
        }

        return {
          id: clinic.referenceId,
          name: clinic.name,
          url: `/clinics/${clinic.referenceId}`,
          icon: 'fa fa-clinic-medical',
          children,
        }
      }) || []
    )
  }, [clinics])

  const navItems: NavItem[] = [
    {
      name: 'Service monitor',
      url: '#/services',
      icon: 'fa fa-laptop-medical',
    },
    {
      name: 'Clinics Operations',
      url: '#/clinics-ops',
      icon: 'fa fa-university',
    },
    // {
    //   title: true,
    //   name: 'Wondermeds',
    //   view: View.WONDERMEDS_SCHEMAS
    // },
    // {
    //   name: 'Schemas',
    //   url: '#/wondermeds/schemas',
    //   icon: 'fa fa-icons',
    //   view: View.WONDERMEDS_SCHEMAS
    // },
    {
      title: true,
      name: 'Echo-Net',
      view: View.JARVIS_ECHO_NET,
    },
    {
      name: 'Users',
      url: '#/users',
      icon: 'fa fa-users',
      view: View.JARVIS_ECHO_NET,
    },
    {
      name: 'Devices',
      url: '#/devices',
      icon: 'fas fa-robot',
      view: View.JARVIS_ECHO_NET,
    },
    {
      name: 'Environments',
      url: '#/environments',
      icon: 'fas fa-code',
      view: View.JARVIS_ECHO_NET,
    },
    {
      title: true,
      name: 'Dispacci',
    },
    {
      name: 'Blacklist',
      url: '#/dispacci/blacklist',
      icon: 'fa fa-user-slash',
      view: View.JARVIS_BLACKLIST,
    },
    {
      name: 'Message Logs',
      url: '#/dispacci/message-logs',
      icon: 'fa fa-comment-dots',
      view: View.JARVIS_MESSAGE_LOG,
    },
    {
      title: true,
      name: 'Passenger',
      view: View.JARVIS_TOUCHPOINT,
    },
    {
      name: 'Touchpoints',
      url: '#/touchpoints',
      icon: 'fa fa-phone',
      view: View.JARVIS_TOUCHPOINT,
    },
    {
      name: 'Touchpoints History',
      url: '#/touchpoints-history',
      icon: 'fa fa-clock',
      view: View.JARVIS_TOUCHPOINT,
    },
    {
      name: 'Templates',
      url: '#/templates',
      icon: 'fa fa-clipboard-list',
      view: View.JARVIS_TOUCHPOINT,
    },
    {
      name: 'Events',
      url: '#/customer-events',
      icon: 'fa fa-paper-plane',
      view: View.JARVIS_TOUCHPOINT,
    },
    {
      title: true,
      name: 'Gap Filler',
      view: View.JARVIS_AVAILY_BOOKING,
    },
    {
      name: 'Settings',
      icon: 'fa fa-calendar',
      url: '#/gap-filler-settings',
      view: View.JARVIS_AVAILY_BOOKING,
    },
    {
      title: true,
      name: 'Recommendation',
    },
    {
      name: 'Types',
      icon: 'fa fa-list',
      url: '#/recommendations',
    },
    {
      title: true,
      name: 'PROMs',
    },
    {
      name: 'Surveys',
      url: '#/surveys',
      icon: 'fa fa-poll',
    },
    {
      title: true,
      name: 'Tools',
    },
    {
      name: 'Person Merger',
      url: '#/redesign/person-merger',
      icon: 'fa fa-toolbox',
      view: View.JARVIS_PERSON_MERGER,
    },
    {
      name: 'Ref ID Finder',
      url: '#/redesign/id-finder',
      icon: 'fa fa-search',
      view: View.JARVIS_PERSON_MERGER,
    },
    {
      name: 'Universal Search',
      url: '#/redesign/universal-search',
      icon: 'fa fa-magnifying-glass-plus',
      view: View.JARVIS_PERSON_MERGER,
    },
    {
      title: true,
      name: 'Core',
    },
    {
      name: 'All clinics',
      url: '#/clinics',
      icon: 'fa fa-clinic-medical',
      view: View.JARVIS_CLINIC,
    },
    {
      name: 'FAQs',
      url: '#/faqs',
      icon: 'fa fa-question',
    },
    {
      name: 'General Clinic Setup',
      icon: 'fa fa-clipboard-list',
      children: [
        {
          name: 'General Clinic Treatments',
          url: '#/general-clinic-treatments',
          icon: 'fa fa-clipboard-list',
          view: View.JARVIS_GENERAL_TREATMENT_BUNDLE,
        },
        {
          name: 'General Treatment Bundles',
          url: '#/general-treatments',
          icon: 'fa fa-book-medical',
          view: View.JARVIS_GENERAL_TREATMENT_BUNDLE,
        },
        {
          name: 'Bundle Categories',
          url: '#/general-bundle-categories',
          icon: 'fa fa-layer-group',
        },
        {
          name: 'Treatment purposes',
          url: '#/general-treatment-purposes',
          icon: 'fa fa-cookie',
        },
        {
          name: 'General Phone Setup',
          url: '#/general-phone-setup',
          icon: 'fa fa-voicemail',
        },
      ],
    },
    ...clinicsNavbarItems,
  ]

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar onToggleDrawer={handleToggleDrawer} />

      <AppDrawer isOpen={open} navItems={navItems} />

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
          [classes.redesign]: isRedesign,
        })}
      >
        <div>
          <Suspense fallback={loading()}>
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (
                  <ProtectedRoute key={idx} path={route.path} exact={route.exact} view={route.view}>
                    <ConditionalTheme theme={isRedesign && redesign}>
                      <route.component {...props} name={route.name} />
                    </ConditionalTheme>
                  </ProtectedRoute>
                ) : null
              })}
              <Redirect from="/" to="/" />
            </Switch>
          </Suspense>
        </div>
      </main>
    </div>
  )
}

export default AppShell
