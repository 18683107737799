import React from 'react'
import { useCredentials, useLogout } from '@dentalux/security'
import { Avatar, Button, Menu, MenuItem } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import getInitials from '../helpers/getInitials'

const useStyles = makeStyles(() => ({
  menuPaper: {
    marginTop: 50,
  },
}))

const UserDropdown: React.FC = () => {
  const { authentication: user } = useCredentials()
  const logout = useLogout()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogoutClick = () => {
    handleClose()
    logout()
  }

  return (
    <>
      <Button color="info" onClick={handleClick}>
        <Avatar src={user?.imgUrl}>{getInitials(user?.name || '')}</Avatar>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menuPaper }}
      >
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
      </Menu>
    </>
  )
}

export default UserDropdown
