import { PropsWithChildren } from 'react'
import { Theme, ThemeProvider } from '@mui/material'

interface ConditionalThemeProps {
  theme: Theme | false
}

export const ConditionalTheme = ({ theme, children }: PropsWithChildren<ConditionalThemeProps>) => {
  if (theme) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
  }
  return <>{children}</>
}
