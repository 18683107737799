import { Env } from './types'

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    env: Env
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.env = window.env || process.env || {}
