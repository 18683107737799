import { echoNetClient } from '.'
import { User, PublicNetwork, Interface, Device } from '../../types'

export const getDevices = async (): Promise<Device[]> => {
  const { data } = await echoNetClient.get('/devices')

  return data.items
}

export const getNetworkInterfaces = async (deviceId: string): Promise<Interface[]> => {
  const { data } = await echoNetClient.get(`/devices/${deviceId}/network/interfaces`)

  return data.items
}

export const getNetworkPublic = async (deviceId: string): Promise<PublicNetwork> => {
  const { data } = await echoNetClient.get(`/devices/${deviceId}/network/public`)

  return data
}

export const getDeviceUsers = async (deviceId: string): Promise<User[]> => {
  const { data } = await echoNetClient.get(`/devices/${deviceId}/users`)

  return data.items
}

export const putDeviceUser = ({ deviceId, userId }: { deviceId: string; userId: string }) => {
  return echoNetClient.put(`/devices/${deviceId}/users/${userId}`)
}

export const deleteDeviceUser = ({ deviceId, userId }: { deviceId: string; userId: string }) => {
  return echoNetClient.delete(`/devices/${deviceId}/users/${userId}`)
}
