const getInitials = (text: string) => {
  if (!text) {
    return ''
  }
  const textParts = text.split(' ').filter((part) => part.toLowerCase() !== 'dr.')

  if (text.length === 0) {
    return ''
  }

  if (textParts.length > 1) {
    return `${textParts[0][0]}${textParts[textParts.length - 1][0]}`.toUpperCase()
  } else {
    return `${textParts[0][0]}`.toUpperCase()
  }
}

export default getInitials
