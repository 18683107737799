import { createTheme } from '@mui/material'

import colors from './colors'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1500,
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    text: {
      primary: colors.dark,
    },
    background: {
      default: 'rgba(134, 153, 163, 0.08)',
    },
    warning: colors.warning,
  },
  typography: {
    fontFamily: 'Rubik, sans-serif',
    fontWeightBold: 500,
    subtitle1: {
      fontWeight: 500,
      color: colors.dark,
    },
    h5: {
      fontSize: '1.25rem',
    },
  },
  components: {
    MuiBreadcrumbs: {
      defaultProps: {
        separator: '›',
      },
      styleOverrides: {
        root: {
          background: colors.jarvisGreyBackground,
          width: 'fit-content',
          fontSize: '14px',
          padding: '8px 16px',
          borderRadius: '20px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          padding: '8px 24px',
        },
        text: {
          color: 'rgba(0, 0, 0, 0.54)',
        },
        textPrimary: {
          color: colors.primary,
        },
        outlinedInfo: {
          color: 'rgba(0, 0, 0, 0.54)',
          borderColor: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: colors.lightLighter,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          alignItems: 'flex-start',

          '& .MuiCheckbox-root': {
            marginTop: -8,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '& .MuiTable-root .MuiTableRow-root:last-of-type .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            background: '#F6FBFB',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          display: 'flex',
          textAlign: 'center',

          '&.MuiAvatar-colorDefault': {
            color: '#f9f9f9',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
  },
})

export default theme
