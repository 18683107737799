import './polyfill'
import './setupEnv'

import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/browser'

import App from './App'

if (window.env.REACT_APP_SENTRY_DSN && window.env.REACT_APP_ENV === 'production') {
  Sentry.init({ dsn: window.env.REACT_APP_SENTRY_DSN })
}

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(<App />)
