import { ApplicationPermission, DataPermission } from '@dentalux/security/lib/types'
import { Nullable, RecommendationCategory, RecommendationPurpose, RecommendationSpeciality } from '@dentalux/common'

import { TREATMENT_PURPOSES_DEPRECATED } from './pages/GeneralClinicTreatment/components/TreatmentPurposeSelectDeprecated'

export interface User {
  id: string
  name: string
  username: string
  email?: string
  isDeletable?: boolean
  role?: Role
}

export interface Role {
  referenceId: string
  name: string
  applicationPermissions: ApplicationPermission[]
  dataPermissions: DataPermission[]
}

export type UserForm = Omit<User, 'id' | 'isDeletable'>

export interface MonitoringInfo {
  first: string
  second: string
}

export interface UserKey {
  created_at: string
  fingerprint: string
  id: string
  key: string
  title: string
  updated_at: string | null
  user_id: string
}

export interface ClinicAlert {
  enabled: boolean
}

export interface ClinicImage {
  url: string
  description: string
  category: string
}

export interface ClinicWebsite {
  brandColorCode?: string | null
  dudaTemplateId?: string | null
  googleAnalyticsViewId?: string | null
  googleAnalyticsPropertyId?: string | null
  googleTagManagerId?: string | null
  googlePlaceId?: string | null
  googleMyBusinessProfileId?: string | null
  reviewWidgetId?: string | null
  websiteUrl?: string | null
  websitePatient21Url?: string | null
  websiteDental21Url?: string | null
  websiteDescription?: string | null
  images?: ClinicImage[] | null
  managedBy: WebsiteProvider
}

export type WebsiteProvider = 'MEDPRESS' | 'EXTERNAL'

export interface ClinicMaxMessageRate {
  maxMessageRate?: number | null
}

export interface Clinic {
  name: string
  referenceId: string
  monitoringInfo: MonitoringInfo[]
  additionalAddressLine?: string
  city?: string
  clinicLogoUrl?: string
  email?: string
  internalSenderEmail?: string
  alertEmail: string
  adminEmail?: string
  houseNumber?: string
  lat?: number
  long?: number
  shortName?: string
  startDate?: string
  endDate?: string
  street?: string
  uuid?: string
  zipCode?: string
  state?: string
  district?: string
  availyStartDate?: string
  jamedaReviewUrl?: string
  maxPractitionerTaskAppointmentsPerDay: number
  sendCustomerEmailAfterBookingMode?: CustomerEmailAfterBooking
  alerts: Record<string, ClinicAlert>
  officialName?: string
  description?: string
  deprecated?: boolean
  officialOpeningHours: WorkingHour[]
  featureFlags?: Record<string, boolean>
  fax?: string | null
  website?: ClinicWebsite
  softwareType: ClinicSoftwareType
  type: ClinicType
  belongsToP21?: boolean
  hasXrayAdapter?: boolean
  hasTiAdapter?: boolean
  personioExternalId?: string
  publicTransportRouteDescription?: string
  carRouteDescription?: string
  legalPerson?: LegalPerson
  supervisory?: Supervisory
  accessibility?: ClinicAccessibility
  availyNewPatientsPerDayLimit?: number
  nfcTags?: NfcTag[]
  specialOpeningHours: SpecialOpeningHours
  internalNote?: string
  phones?: ClinicPhones
  shadowBookingPerDayLimit?: number
  externalOnlineBookingServiceUrl?: string
  tenantReferenceId?: Nullable<string>
  tenant?: Nullable<Tenant>
  deviceIds?: string[]
  bfsMandateNumber?: Nullable<string>
  pmsUrl?: Nullable<string>
  overtimeHandling: OvertimeHandling
  dentalSupplementaryInsuranceBroker?: InsuranceBroker | null
  status: ClinicStatus
}

export enum ClinicStatus {
  WILL_BE_ACTIVE = 'WILL_BE_ACTIVE',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum InsuranceBroker {
  PRIVADENT = 'PRIVADENT',
  DZVS = 'DZVS',
}

export type OvertimeHandling = 'APPROVAL_BY_CLINIC' | 'APPROVAL_BY_REGIO'

export interface Tenant {
  referenceId: string
  name: string
  internal: boolean
}

export interface TenantType extends Tenant {
  deprecated: boolean
  internal: boolean
}

export interface ClinicPhones {
  legacyNumber?: string
  defaultTwilioNumber?: string
  smsNumber?: string
  gmbProfileNumber?: string
  dental21WebsiteNumber?: string
  patient21WebsiteNumber?: string
  clinicWebsiteNumber?: string
  dynamicNumbers?: DynamicClinicPhone[]
}

export interface DynamicClinicPhone {
  label: string
  number: string
}

export interface ClinicItOps {
  name: string
  referenceId: string
  email?: string
  phone?: string
  shortName?: string
  key: string
  startDate?: string
  availyStartDate?: string
  officialName?: string
  website?: string
  clinicSoftwareType: ClinicSoftwareType
  monitoringInfo: MonitoringInfo[]
  serviceDeskKey?: string
  serviceDeskHealth: ClinicServiceDeskHealth
  serviceDeskTicketCountByPriority: TicketCount
  chiefDentist: PractitionerBasic[]
  praxisManager?: PractitionerBasic
  regionalPraxisManager?: PractitionerBasic
  regionalManager?: PractitionerBasic
}

export type TicketCount = {
  [key in ServiceDeskTicketPriority]: number
}

export enum ClinicServiceDeskHealth {
  HEALTHY = 'HEALTHY',
  LOW_RISK = 'LOW_RISK',
  MEDIUM_RISK = 'MEDIUM_RISK',
  HIGH_RISK = 'HIGH_RISK',
}

export enum ServiceDeskTicketPriority {
  HIGHEST = 'HIGHEST',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  UNKNOWN = 'LOW',
}

export interface NfcTag {
  tag: string
  deprecated: boolean
}

export type SpecialOpeningHours = { [name in SpecialOpeningHoursName]?: WorkingHour[] }

export enum SpecialOpeningHoursName {
  ACUTE_PAIN = 'ACUTE_PAIN',
  INFECTION = 'INFECTION',
  APPOINTMENT_ONLY = 'APPOINTMENT_ONLY',
}

interface ClinicAccessibility {
  wheelchairAccessibleEntrance: boolean
  wheelchairAccessibleElevator: boolean
  barrierFree: boolean
}

export enum HealthStatus {
  HEALTHY = 'HEALTHY',
  UNHEALTHY = 'UNHEALTHY',
}

export enum HealthType {
  HEARTBEAT = 'HEARTBEAT',
  ADAPTER_SW_VERSION = 'ADAPTER_SW_VERSION',
  REVENUES_SYNC = 'REVENUES_SYNC',
  TREATMENTS_SYNC = 'TREATMENTS_SYNC',
  COST_PLANS_SYNC = 'COST_PLANS_SYNC',
  ROOMS_SYNC = 'ROOMS_SYNC',
  TREATMENT_GROUPS_SYNC = 'TREATMENT_GROUPS_SYNC',
}

export interface ClinicForm extends Partial<Clinic> {
  officialOpeningHours: WorkingHour[]
  bookingTool?: 'AVAILY' | 'EXTERNAL'
  dispacci?: {
    maxMessageRate?: number | null
  }
  monitoring?: {
    pms: { connectionEnabled: boolean; softwareEnabled: boolean }
    xrayAdapter: { connectionEnabled: boolean; softwareEnabled: boolean }
    tiAdapter: {
      connectionEnabled: boolean
      softwareEnabled: boolean
      easyTiSoftwareEnabled: boolean
      tiConnectionEnabled: boolean
    }
    device: { connectionEnabled: boolean }
  }
}

export interface LegalPerson {
  name: string
  street: string
  houseNumber?: string
  city: string
  zipCode: string
  court: string
  registerNumber: string
  supervisoryAuthority: string
  chamber: string
  vatNumber: string
}

export interface Supervisory {
  body?: string
  street?: string
  city?: string
  phoneNumber?: string
  email?: string
}

export interface ImageUploadForm {
  title: string
}

export interface Device {
  id: string
  name: string
  network_ip: string
  tunnel_ip: string
  status: string
  public: {
    ip: string
  }
  updated_at: string
  clinicIds: string[]
}

export interface PublicNetwork {
  ip?: string
  ip_decimal?: number
  latitude?: number
  longitude?: number
  asn?: string
  asn_org?: string
  city?: string
  country?: string
  country_eu?: boolean
  country_iso?: string
  hostname?: string
}

export interface Interface {
  broadcast: string
  inet: string
  inet6: string[]
  name: string
  netmask: string
}

export enum BookingType {
  AVAILY = 'AVAILY',
  ADVISOR_RECALL_REMINDER = 'ADVISOR_RECALL_REMINDER',
  UPDATE = 'UPDATE',
  ADVISOR_APPOINTMENT = 'ADVISOR_APPOINTMENT',
}

export interface Booking {
  accepted: boolean
  acknowledgeMessage: string
  comment: string
  created: string
  durationInMinutes: number
  ignore: boolean
  referenceId: string
  clinicReferenceId: string
  resourceId: string
  reviewed: boolean
  start: string
  status: 'BOOKED' | 'PENDING' | 'ERROR'
  treatmentBundleReferenceId: string
  customer?: Customer
  events?: BookingEvent[]
  type: BookingType
  treatmentInfo?: {
    treatmentBundleReferenceId?: string
    treatmentBundleTitle?: string
  }
  bookingAppointmentsInfo?: BookingAppointmentInfo[]
  errorEmailSentAt: string | null
}

export interface BookingAppointmentInfo {
  start?: string
  practitionerName?: string
  clinicTreatmentTitle?: string
  practitionerReferenceId?: string
  appointmentReferenceId?: string
  durationInMinutes?: number
}

export interface BookingEvent {
  clinicPractitionerId?: string
  clinicTreatmentId?: string
  practitionerReferenceId: string
  treatmentReferenceId: string
  durationInMinutes: number
  start: string
}

export interface CustomerAppointment {
  comment?: string
  durationInMinutes: number
  referenceId: string
  start: string
  cancelState?: string
  createdAt: string
  practitionerReferenceId?: string
  state?: string
}

export interface Customer {
  email: string
  firstName: string
  lastName: string
  phone: string
  resourceId: string
  gender?: string
  insuranceType?: string
  patientType?: string
}

export interface CostPlan {
  description: string
  referenceId: string
  start: string
  status: CostPlanStatus
  subStatus: CostPlanSubStatus
  totalCosts: number
  type: CostPlanType
  estimatedAcceptedAt: string
  createdAt: string
  isDeleted: boolean
}

export interface CustomerResource {
  appointments?: CustomerAppointment[]
  email?: string
  firstName?: string
  gender?: string
  insuranceType?: string
  lastAppointmentDate?: string
  nextAppointmentDate?: string
  lastName?: string
  phoneNumber?: string
  clinicName?: string
  referenceId: string
  type: string
  costPlans?: CostPlan[] | null
}

export interface ShortRoomSummaryInfo {
  referenceId: string
}

export interface PractitionerPosition {
  role: PractitionerRole
  level?: PractitionerLevel
}

export interface Practitioner {
  /** @deprecated use positions instead */
  role: PractitionerRole
  /** @deprecated use positions instead */
  level?: PractitionerLevel
  positions: PractitionerPosition[]
  cvEntries: CVEntry[]
  constrainedRoomReferenceIds: string[]
  active: boolean
  externalId: string
  firstWorkDay: string
  email: string
  imageUrl: string
  jamedaReviewUrl: string
  lastWorkDay: string
  threeLetterId?: string
  displayName: string
  useCustomDisplayName?: boolean
  firstName?: string
  lastName?: string
  referenceId: string
  weeklyWorkSchedule: WeeklyWorkSchedule
  availableRooms?: RoomSummaryInfo[]
  enableForAvaily: boolean
  corrupt: boolean
  deleted: boolean
  deprecated?: boolean
  spokenLanguageCodes?: string[]
  countryFlagCodes?: string[]
  memberships: Organization[]
  description: string
  degree: string
  personioEmployeeId: string | null
  excludedTreatmentReferenceIds?: string[]
  googleUserId?: string
  googleOneTimePassword?: string
  weeklyWorkingHours?: number
  riseAccountStatus?: RiseAccountStatus
  group?: string
  status: PractitionerStatus
  personioUpToDate: boolean
  internalNote?: string
  canWorkFromHome: boolean
  personioDeleted: boolean
  specialisations?: PractitionerSpecialisation[]
  displayRole?: string | null
}

export interface PractitionerBasic {
  referenceId: string
  firstName: string
  lastName: string
  email: string
}

export enum PractitionerStatus {
  DISABLED = 'DISABLED',
  INACTIVE = 'INACTIVE',
  WILL_BE_ACTIVE = 'WILL_BE_ACTIVE',
  ACTIVE = 'ACTIVE',
  USED_TO_BE_ACTIVE = 'USED_TO_BE_ACTIVE',
}

export enum RiseAccountStatus {
  NOT_CONNECTED = 'NOT_CONNECTED',
  INVITED = 'INVITED',
  HAS_ACCOUNT = 'HAS_ACCOUNT',
}

export enum TreatmentDataType {
  DENTAL_CLEANING = 'DENTAL_CLEANING',
  OTHER = 'OTHER',
  FOLLOW_UP = 'FOLLOW_UP',
  PARADONTOSIS_ANAMNESE = 'PARADONTOSIS_ANAMNESE',
  INVISALIGN_CONSULTING = 'INVISALIGN_CONSULTING',
}

export enum FAQAdjustmentType {
  EXCLUDED = 'Excluded',
  ADDED = 'Added',
}

export type TreatmentFAQ = {
  faqReferenceId: string
  order: number | null
  adjustmentType: FAQAdjustmentType | null
}

export interface TreatmentVisibilityDetails {
  showInAvaily?: boolean
  showInCalmaster?: boolean
  showOnWebsite?: boolean
}

export interface TreatmentWebsiteDetails {
  websiteDescription?: string
  showTreatmentDetailsPage: boolean
}

interface TreatmentStepRequest extends Omit<TreatmentStep, 'clinicTreatment'> {
  clinicTreatmentReferenceId: string
}

export interface SaveTreatmentBundleRequest extends Omit<TreatmentBundle, 'plans' | 'referenceId'> {
  plans: (Omit<TreatmentPlan, 'steps'> & {
    steps: TreatmentStepRequest[]
  })[]
  treatmentBundleCategoryReferenceId?: string
  treatmentPurposeReferenceId?: string
  tenantReferenceId?: string
}

export interface TreatmentBundle extends TreatmentWebsiteDetails, TreatmentVisibilityDetails {
  imageUrl?: string | null
  iconUrl?: string | null
  referenceId: string
  title: string
  type?: TreatmentDataType
  visibleIndex?: number | null
  plans: TreatmentPlan[]
  enabled?: boolean
  manualReviewRequired: boolean
  coreTreatmentReferenceId?: string
  coreTreatmentTitle?: string
  coreTreatmentTypeTitle?: string
  coreTreatmentType?: TreatmentDataType
  visualHint?: string
  overrideGeneralTreatmentBundle?: boolean
  assignedGeneralTreatmentBundleReferenceId?: string
  bundleType?: TreatmentBundleType
  faqs: TreatmentFAQ[]
  canUpdateClinicType?: boolean
  clinicType: ClinicType
  patientExperienceFlows?: PatientExperienceFlow[]
  emailTemplateId?: string
  clinicReferenceId?: string
  purpose?: TreatmentPurposeDeprecated
}

export type TreatmentPurposeDeprecated = keyof typeof TREATMENT_PURPOSES_DEPRECATED

export interface GeneralTreatmentLinks {
  rel: string
  href: string
  hreflang: string
  media: string
  title: string
  type: string
  deprecation: string
  profile: string
  name: string
}

export interface GeneralTreatmentStep {
  restrictedStartMinutes: number[]
  durationInMinutes: number
  practitionerRole: PractitionerRole
  useForPreferredPractitioners: boolean
  adjustments?: TreatmentAdjustments
  startingTimeOptions?: TimeOffsetSelector[]
  generalClinicTreatment: {
    referenceId: string
  }
  onlyIncludeToBundleIfFullyBillable: boolean
}

export interface GeneralTreatmentPlan {
  title: string
  patientType?: PatientType
  steps: GeneralTreatmentStep[]
}

export type GeneralTreatmentFAQ = {
  referenceId: string
  order: number
  checked: boolean
}

export interface GeneralTreatmentBundle extends TreatmentWebsiteDetails, TreatmentVisibilityDetails {
  imageUrl?: string | null
  iconUrl?: string | null
  referenceId: string
  title: string
  enabled: boolean
  type?: TreatmentDataType
  visibleIndex?: number | null
  visualHint?: string
  plans?: GeneralTreatmentPlan[]
  assignedGeneralTreatmentBundleReferenceId?: string
  faqs?: GeneralTreatmentFAQ[]
  clinicType: ClinicType
  canUpdateClinicType?: boolean
  manualReviewRequired: boolean
  generalPatientExperienceFlows?: PatientExperienceFlow[]
  translations?: TreatmentBundleTranslations
  purpose?: TreatmentPurposeDeprecated
  treatmentBundleCategoryReferenceId?: string
  treatmentPurpose?: TreatmentPurposeBasic
  category?: BundleCategoryBasic
  tenant: Nullable<Tenant>
  appointmentStartOffsetDays?: Nullable<number>
}

export interface BundleCategoryBasic {
  referenceId: string
  contentfulKey?: string
  iconUrl?: string
}

export interface BaseFAQ {
  question: string
  answer: string
}

export interface FAQEntry extends BaseFAQ {
  referenceId: string
}

export interface FAQ extends FAQEntry {
  order: number
  checked: boolean
}

export interface GeneralClinicTreatmentsData {
  deleted: boolean
  durationInMin: number
  enabledReminder: boolean
  links: GeneralTreatmentLinks[]
  overBookingMode: string
  referenceId: string
  title: string
  usedForRecall: boolean
  overBookingData?: OverBookingData | null
  primaryOverBookingData?: OverBookingData | null // Deprecated - to be removed after https://dentalux.atlassian.net/browse/D21-30868
  overrideGeneralClinicTreatment?: boolean | false
  assignedGeneralClinicTreatmentReferenceId: string | null
  clinicType: ClinicType
  bookableByPatient?: boolean
  requiredPractitionerRoles?: PractitionerRole[]
}

export interface TreatmentPlan {
  patientType?: PatientType
  title: string
  steps: TreatmentStep[]
}

export type PatientType = 'NEW' | 'OLD' | ''

export interface TreatmentAdjustment {
  start?: string | null
  end?: string | null
  durationInMinutes?: number
}

export type TreatmentAdjustments = { [key in keyof PractitionerLevel]?: TreatmentAdjustment }

export interface TreatmentStep {
  restrictedStartMinutes?: number[]
  durationInMinutes: number
  clinicTreatment: ClinicTreatment
  practitionerRole: PractitionerRole
  useForPreferredPractitioners: boolean
  adjustments?: TreatmentAdjustments
  startingTimeOptions?: TimeOffsetSelector[]
  onlyIncludeToBundleIfFullyBillable: boolean
}

export interface ClinicTreatment extends SharedClinicTreatmentData {
  externalId: string
  durationInMin?: number
  enabledReminder: boolean
  deprecated: boolean
  externalDeprecated: boolean
  usedForRecall: boolean
  overBookingMode: OverBookingMode
  overBookingData?: OverBookingData | null
  primaryOverBookingData?: OverBookingData | null // Deprecated - to be removed after https://dentalux.atlassian.net/browse/D21-30868
  overrideGeneralClinicTreatment?: boolean | false
  assignedGeneralClinicTreatmentReferenceId: string | null
  assignedTreatmentGroupReferenceId?: string

  bookableByPatient?: boolean

  clinic: {
    shadowBookingEnabled: boolean
  }

  assignedGeneralClinicTreatment?: GeneralClinicTreatment
  shadowBookingData?: ShadowBookingData
}

export interface SaveClinicTreatment extends Omit<ClinicTreatment, 'treatmentPurpose'> {
  treatmentPurposeReferenceId: string | undefined
}

export interface TreatmentTypeForm {
  assignedTreatmentGroupReferenceId: string
}

export interface TreatmentType {
  referenceId: string
  externalId: string
  name: string
  externalColorCode: string
  colorCode?: string
  deleted: boolean
}

export interface SharedTreatmentBundleStep {
  restrictedStartMinutes?: number[]
  durationInMinutes: number
  clinicTreatmentReferenceId: string
  practitionerRole: PractitionerRole
  useForPreferredPractitioners: boolean
  adjustments?: TreatmentAdjustments
  startingTimeOptions?: TimeOffsetSelector[]
  onlyIncludeToBundleIfFullyBillable: boolean
}

export interface SharedTreatmentBundlePlan {
  patientType?: PatientType
  title: string
  steps: SharedTreatmentBundleStep[]
}

export interface SharedTreatmentBundleForm {
  imageUrl?: string
  iconUrl?: string
  referenceId?: string
  visibleIndex?: number
  type?: TreatmentDataType
  title: string
  visualHint?: string
  plans?: SharedTreatmentBundlePlan[]
  enabled?: boolean
  clinicType: ClinicType
  patientExperienceFlows?: PatientExperienceFlow[]
  manualReviewRequired: boolean
  websiteDescription?: string
  showInAvaily?: boolean
  showOnWebsite?: boolean
  translations?: TreatmentBundleTranslations
  purpose?: TreatmentPurposeDeprecated
  treatmentPurpose?: TreatmentPurposeBasic
  category?: BundleCategoryBasic
  treatmentBundleCategoryReferenceId?: string
  tenantReferenceId?: string
  appointmentStartOffsetDays?: Nullable<number>
  showInCalmaster?: boolean
}

/**
 * Abstraction level of treatment bundle resource which have an inheritance hierarchy:
 * general-treatment-bundle is parent of treatment-bundle-type.
 */
export enum TreatmentBundleType {
  //Treatment bundle is a general resource used for reoccurring settings
  GENERAL = 'GENERAL',
  // Treatment bundle has a general parent but customize it by overriding its settings
  CUSTOMIZED = 'CUSTOMIZED',
  // Treatment bundle doesn't have a general parent and is individual resource
  INDIVIDUAL = 'INDIVIDUAL',
}

export interface UpdateTreatmentBundleForm extends SharedTreatmentBundleForm {
  bundleType?: TreatmentBundleType
  overrideGeneralTreatmentBundle?: boolean
  faqs: TreatmentFAQ[]
  showTreatmentDetailsPage: boolean
}

export interface UpdateGeneralTreatmentBundleForm extends SharedTreatmentBundleForm {
  faqs: GeneralTreatmentFAQ[]
  showTreatmentDetailsPage: boolean
}

interface GeneralTreatmentStepRequest extends Omit<GeneralTreatmentStep, 'generalClinicTreatment'> {
  generalClinicTreatmentReferenceId: string
}

export interface SaveGeneralTreatmentBundleRequest
  extends Omit<GeneralTreatmentBundle, 'tenant' | 'plans' | 'referenceId' | 'treatmentPurpose'> {
  referenceId?: string
  plans: (Omit<GeneralTreatmentPlan, 'steps'> & {
    steps: GeneralTreatmentStepRequest[]
  })[]
  treatmentPurposeReferenceId: string | undefined
  treatmentBundleCategoryReferenceId: string | undefined
  tenantReferenceId: string | undefined
}

export interface PractitionerForm extends Partial<Practitioner> {
  organizationReferenceIds?: string[]
}

export enum PractitionerRole {
  NONE = 'NONE',
  ACCOUNTANT = 'ACCOUNTANT',
  ASSISTANT = 'ASSISTANT',
  CLINIC_MANAGER = 'CLINIC_MANAGER',
  DENTAL_TECHNICIAN = 'DENTAL_TECHNICIAN',
  DENTIST = 'DENTIST',
  HYGIENIST = 'HYGIENIST',
  RECEPTIONIST = 'RECEPTIONIST',

  DOCTOR = 'DOCTOR',
  TREATER = 'TREATER',
}

export enum PractitionerLevel {
  CHIEF = 'CHIEF',
  SENIOR = 'SENIOR',
  JUNIOR = 'JUNIOR',
  GRADUATE = 'GRADUATE',
  WORKING_STUDENT = 'WORKING_STUDENT',
  TRAINEE = 'TRAINEE',
  INTERN = 'INTERN',
}

export interface WeeklyWorkSchedule {
  clinicWorkSchedules: WorkingHour[]
  modifiedWorkSchedules: WorkingHour[]
}

export enum WeekDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface WorkingHour {
  timeFrames: TimeFrame[]
  weekDay: WeekDay
}

export interface TimeFrame {
  end: string
  start: string
}

export enum BlacklistType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export interface BlacklistItem {
  type: BlacklistType
  target: string
  since?: string // ISO date string
}

export enum FetchStatus {
  NotAsked = 'NotAsked',
  Loading = 'Loading',
  Success = 'Success',
  Failure = 'Failure',
}

export interface Env {
  REACT_APP_HADES_URL?: string
  REACT_APP_CORE_APP_ENDPOINT?: string
  REACT_APP_ECHO_NET_ENDPOINT?: string
  REACT_APP_MONITORING_ENDPOINT?: string
  REACT_APP_GRAFANA_DASHBOARD?: string
  REACT_APP_PASSENGER_ENDPOINT?: string
  REACT_APP_EMPLOYEE_ENDPOINT?: string
  REACT_APP_PATIENT_ENDPOINT?: string
  REACT_APP_ANA_ENDPOINT?: string
  REACT_APP_RECOMMENDATION_ENDPOINT?: string
  REACT_APP_CLINIC_ENDPOINT?: string
  REACT_APP_HADES_ENDPOINT?: string
  REACT_APP_MEDPRESS_ENDPOINT?: string
  REACT_APP_WONDERMEDS_ENDPOINT?: string
  REACT_APP_DOC_CIRRUS_ADAPTER_ENDPOINT?: string
  REACT_APP_MEDICAL_RECORDS_ENDPOINT?: string
  REACT_APP_UPLOADCARE_PUBLIC_KEY: string
  REACT_APP_SENTRY_DSN?: string | null
  REACT_APP_ENV?: string
  REACT_APP_DISPACCI_ENDPOINT?: string
  REACT_APP_HADES_ENV?: string
  REACT_APP_OPERATOR_URL?: string
  REACT_APP_GAP_FILLER_URL?: string
  REACT_APP_PROMS_ENDPOINT?: string
  REACT_APP_POSTHOG_API_KEY?: string
  REACT_APP_POSTHOG_API_HOST?: string
  NODE_ENV?: string
  REACT_APP_CONTENTFUL_API_KEY?: string
  REACT_APP_CONTENTFUL_SPACE_ID?: string
  REACT_APP_PATIENT21_LOGO_PREVIEW: string
}

export type AdditionalProperties = { key: string; value: string }

export interface CustomerAction {
  templateReferenceId?: string
  templateText?: string
  type: ActionType
  smsSenderIdType?: SmsSenderIdType
  target: ActionTarget
}

export enum SmsSenderIdType {
  CLINIC_PHONE_NUMBER = 'CLINIC_PHONE_NUMBER',
  DOCTOR_TEXT = 'DOCTOR_TEXT',
  PRACTITIONER_NAME = 'PRACTITIONER_NAME',
}

export type ActionType = 'SEND_SMS' | 'SEND_EMAIL' | 'SEND_LETTER'
export type ActionTarget = 'CLINIC' | 'CUSTOMER'

export interface CustomerActionInput {
  templateReferenceId?: string
  type: ActionType
  smsSenderIdType?: SmsSenderIdType
  target: ActionTarget
}

export interface AdditionalTemplateVariable {
  name: string
  value: string
}

export interface CustomerFilter {
  type: string
  values: string[]
}

export type LoadTimeType = 'NONE' | 'LAST' | 'NEXT' | 'LONGEST'

export type RequiresHealthAssistantLinkType = 'NOT_REQUIRED' | 'OPTIONAL' | 'REQUIRED'

export type RequiresHappyLinkType =
  | 'DISABLED'
  | 'WITH_APPOINTMENTS_FROM_TODAY'
  | 'WITH_APPOINTMENTS_FROM_YESTERDAY'
  | 'WITH_EMAIL_FEEDBACK'

export type IcsAttachmentType =
  | 'DISABLED'
  | 'UPCOMING_APPOINTMENT_BUNDLE'
  | 'FIRST_APPOINTMENT_BUNDLE_AT_DAY_OF_REFERENCED_APPOINTMENT'
  | 'REFERENCED_APPOINTMENT_BUNDLE'

export enum TouchpointConditionType {
  NEVER = 'NEVER',
  HOURS_BEFORE_NEXT_NON_CANCELED_APPOINTMENT = 'HOURS_BEFORE_NEXT_NON_CANCELED_APPOINTMENT',
  HOURS_AFTER_LAST_VISITED_APPOINTMENT = 'HOURS_AFTER_LAST_VISITED_APPOINTMENT',
  AT_SPECIFIC_DAY_OF_WEEK_AND_TIME = 'AT_SPECIFIC_DAY_OF_WEEK_AND_TIME',
  HOURS_AFTER_COST_PLAN_CREATION = 'HOURS_AFTER_COST_PLAN_CREATION',
  AT_HOUR_OF_RECALL_DAY = 'AT_HOUR_OF_RECALL_DAY',
  MIN_AFTER_LAST_VISITED_APPOINTMENT_END_OF_DAY = 'MIN_AFTER_LAST_VISITED_APPOINTMENT_END_OF_DAY',
  MINUTES_AFTER_NON_AVAILY_APPOINTMENT_EXTERNAL_CREATED = 'MINUTES_AFTER_NON_AVAILY_APPOINTMENT_EXTERNAL_CREATED',
  MINUTES_AFTER_AVAILY_APPOINTMENT_EXTERNAL_CREATED = 'MINUTES_AFTER_AVAILY_APPOINTMENT_EXTERNAL_CREATED',
  HOURS_AFTER_APPOINTMENT_OF_TRIGGER = 'HOURS_AFTER_APPOINTMENT_OF_TRIGGER',
  MINUTES_AFTER_AVAILY_APPOINTMENT_WITH_TREAT_MANUAL_REVIEWED_REQUIRED_EXTERNAL_CREATED = 'MINUTES_AFTER_AVAILY_APPOINTMENT_WITH_TREAT_MANUAL_REVIEWED_REQUIRED_EXTERNAL_CREATED',
  MINUTES_AFTER_AVAILY_APPOINTMENT_WITH_TREAT_MANUAL_REVIEWED_NOT_REQUIRED_EXTERNAL_CREATED = 'MINUTES_AFTER_AVAILY_APPOINTMENT_WITH_TREAT_MANUAL_REVIEWED_NOT_REQUIRED_EXTERNAL_CREATED',
  RIGHT_AFTER_MISSED_CALL_TO_CLINIC = 'RIGHT_AFTER_MISSED_CALL_TO_CLINIC',
  RIGHT_AFTER_MISSED_NON_PATIENT_CALL_TO_CLINIC = 'RIGHT_AFTER_MISSED_NON_PATIENT_CALL_TO_CLINIC',
  MINUTES_AFTER_APPOINTMENT_HAS_BEEN_MOVED = 'MINUTES_AFTER_APPOINTMENT_HAS_BEEN_MOVED',
  MINUTES_AFTER_PATIENT_ACCOUNT_APPOINTMENT_EXTERNAL_CREATED = 'MINUTES_AFTER_PATIENT_ACCOUNT_APPOINTMENT_EXTERNAL_CREATED',
  MINUTES_AFTER_NO_SHOW = 'MINUTES_AFTER_NO_SHOW',
  MINUTES_AFTER_ONLINE_CANCELLATION = 'MINUTES_AFTER_ONLINE_CANCELLATION',
  EVERY_X_DAYS_AFTER_RECALL = 'EVERY_X_DAYS_AFTER_RECALL',
  AT_HOUR_OF_RECALL_FOR_DENTAL_CHECKUP_DAY = 'AT_HOUR_OF_RECALL_FOR_DENTAL_CHECKUP_DAY',
  AT_HOUR_OF_RECALL_FOR_DENTAL_CLEANING_DAY = 'AT_HOUR_OF_RECALL_FOR_DENTAL_CLEANING_DAY',
  AT_HOUR_OF_RECALL_FOR_KIDS_DENTAL_CLEANING_DAY = 'AT_HOUR_OF_RECALL_FOR_KIDS_DENTAL_CLEANING_DAY',
  AT_HOUR_OF_RECALL_AFTER_COST_PLAN_KBR = 'AT_HOUR_OF_RECALL_AFTER_COST_PLAN_KBR',
  AT_HOUR_OF_RECALL_AFTER_COST_PLAN_ZE = 'AT_HOUR_OF_RECALL_AFTER_COST_PLAN_ZE',
}

export enum RepeatingActionFilterType {
  NONE = 'NONE',
  BY_CUSTOMER = 'BY_CUSTOMER',
  BY_CUSTOMER_AND_DAYS_VALUE = 'BY_CUSTOMER_AND_DAYS_VALUE',
  BY_CUSTOMER_AND_REFERENCED_APPOINTMENT_BUNDLE_FOR_FIRST_VALID_APPOINTMENT_OF_BUNDLE = 'BY_CUSTOMER_AND_REFERENCED_APPOINTMENT_BUNDLE_FOR_FIRST_VALID_APPOINTMENT_OF_BUNDLE',
  BY_CUSTOMER_AND_REFERENCED_APPOINTMENT_START_DATE = 'BY_CUSTOMER_AND_REFERENCED_APPOINTMENT_START_DATE',
}

export interface TouchpointCustomerData {
  referenceId?: string
  email: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  gender?: string
}

export interface TouchpointClinicContextData {
  clinicReferenceId: string
  missedCallNumber: string
}

export interface TouchpointResourceInput {
  actions: CustomerActionInput[]
  mailUnsubscribeGroupExternalId?: string
  additionalTemplateVariables?: AdditionalTemplateVariable[]
  enabled: boolean
  sandboxMode?: boolean
  name: string
  filters: CustomerFilter[]
  conditionType: TouchpointConditionType
  conditionValue: string
  repeatingActionFilterType: RepeatingActionFilterType
  repeatingActionFilterValue?: string
  loadPractitioner: LoadTimeType
  requiresHappyLink: RequiresHappyLinkType
  requiresAnaLink: boolean
  requiresCostPlanLink: boolean
  attachIcsFileForAppointmentType: IcsAttachmentType
  treatmentType?: TreatmentDataType
  requiresHealthAssistantRegistrationLinkStrategy: RequiresHealthAssistantLinkType
}

export interface TouchpointResource {
  actions: CustomerActionInput[]
  mailUnsubscribeGroupExternalId?: string
  additionalTemplateVariables?: AdditionalTemplateVariable[]
  enabled: boolean
  sandboxMode?: boolean
  name: string
  filters: CustomerFilter[]
  referenceId: string
  conditionType: TouchpointConditionType
  conditionValue: string
  repeatingActionFilterType: RepeatingActionFilterType
  repeatingActionFilterValue?: string
  requiresAvailableAppointments: boolean
  loadPractitioner: LoadTimeType
  requiresHappyLink: RequiresHappyLinkType
  requiresAnaLink: boolean
  requiresCostPlanLink: boolean
  attachIcsFileForAppointmentType: IcsAttachmentType
  treatmentType?: TreatmentDataType
  requiresHealthAssistantRegistrationLinkStrategy: RequiresHealthAssistantLinkType
}

export type TouchpointsHistoryResource = {
  revision: {
    dateTime: string
    revision: number
    revisionType: 'INSERT' | 'DELETE' | 'UPDATE'
    touchPointId: string
    user: string
  }
  diff?: Record<string, unknown>
}

export interface TemplateResource {
  title: string
  referenceId: string
  templateText: string
}

export interface TemplateResourceInput {
  title: string
  templateText: string
}

export interface TouchpointFilterMeta {
  valueDataType: string
  filterType: TouchpointFilterType
  filterTypeCategory: string
  allowedValues?: {
    value: string
    visibleValue: string
  }[]
  filterTypeVisibleName?: string
  allowsMultipleValues: boolean
}

export type TouchpointFilterMetaByFilterType = Record<TouchpointFilterType, TouchpointFilterMeta>

export interface CustomerEventResource {
  contactType: string
  customerId: string
  customerName: string
  errorMessage: string
  referenceId: string
  status: string
  timeStamp: string
  title: string
  touchPointEventId: string
  touchPointId: string
}

export interface CustomerEventFilter {
  touchpointReferenceId?: string
  start?: string
  end?: string
}

export interface PageFilters {
  pageNumber?: number
  pageSize?: number
}

export interface PageMetaData {
  number: number // page number
  size: number
  totalElements: number
  totalPages: number
}

export type EnvironmentModel = 'global' | 'clinic' | 'device' | 'static'

export type EnvironmentEntryType = 'file' | 'file-no-quotes' | 'systemd'

export interface Environment {
  type: EnvironmentEntryType
  reference: string
  entries: EnvironmentEntry[]
}

export interface EnvironmentEntry {
  id: string
  type?: EnvironmentEntryType
  type_reference?: string
  key: string
  value: string
  isDeletable?: boolean
  model?: EnvironmentModel
  model_id?: string
}

export enum RoomType {
  DENTAL_CLEANING = 'DENTAL_CLEANING',
  DENTAL = 'DENTAL',
  ALL = 'ALL',
  NO_TREATMENTS = 'NO_TREATMENTS',
}

export interface Room {
  clinicReferenceId: string
  enabled: boolean
  end: string
  externalCreated?: string
  externalDeleted: boolean
  externalDeprecated: boolean
  externalId: string
  externalUpdated?: string
  name: string
  referenceId: string
  start: string
  type: RoomType
  visibleName: string
  weekSchedules: WorkingHour[]
  assignedPractitioners?: PractitionerSummaryInfo[]
}

export interface PractitionerSummaryInfo {
  referenceId: string
  name: string
  imageUrl?: string
}

export interface RoomSummaryInfo {
  visibleName: string
  referenceId: string
  type: RoomType
}

export interface Patient {
  externalId: string
  referenceId: string
  firstName: string
  lastName: string
}

export interface AppointmentInternal {
  referenceId: string
  clinicReferenceId: string
  practitionerReferenceId: string
  employeeReferenceId: string
  roomReferenceId: string | null
  patient: Patient
  employee: Pick<Practitioner, 'referenceId' | 'displayName' | 'imageUrl'>
  title: string
  treatmentName: string
  treatmentReferenceId: string
  comment: string
  startTime: string
  endTime: string
  state: string
  appointmentBundleReferenceId: string
  stateInClinicStart: string | null
  stateWaitingStart: string | null
  stateInTreatmentRoomStart: string | null
  stateTreatmentStart: string | null
  stateTreatmentEnd: string | null
  cancellationType: string | null
  cancellationReason: string | null
  controlled: boolean
  controlledAccounting: boolean
  colorCode: string
  cancelledBy: string | null
  cancelledAt: string | null
  reasonForVisit: string | null
  blocking: boolean
  shadowBooked: boolean
  deleted: boolean
  createdAt: string
  modifiedAt: string
}

export interface Appointment {
  referenceId: string
  externalId: string
  start: string
  durationInMinutes: number
  title: string
  deleted: boolean
  canceled: boolean | null
  createdAt?: string
  modified: string
  bookingType: BookingType | null
  room: Pick<Room, 'referenceId' | 'name' | 'type'>
  practitioner: {
    referenceId: string
    name: string
  }
  clinic: Pick<Clinic, 'referenceId' | 'name' | 'shortName'>
  patient: Patient
  treatment: Pick<ClinicTreatment, 'referenceId' | 'externalTitle' | 'externalDeprecated' | 'deleted' | 'deprecated'>
  rescheduleAppointmentBundles?: { referenceId: string }[]
}

export interface ClinicTreatmentInfo {
  coreTreatmentReferenceId?: string
  coreTreatmentTitle?: string
  coreTreatmentType?: string
  coreTreatmentTypeTitle?: TreatmentDataType
  referenceId: string
  title?: string
}

export type AppointmentSummary = {
  appointmentReferenceId: string
  clinicReferenceId: string
  durationInMinutes: number
  patientReferenceId?: string
  practitioner?: PractitionerSummaryInfo
  sourceStart: string
  targetStart: string
  title?: string
  treatment?: ClinicTreatmentInfo
}

export interface APIError {
  errorCode: string
  status: string
  message: string
}

export interface WarningAlert {
  title: string
  details?: unknown
  closeable?: boolean
}

export type FlatPagedResource<Resource> = {
  content: Resource[]
  number: number // Page number
  numberOfElements: number // Elements in the current page
  totalElements: number
  size: number
  totalPages: number
}

export type PagedResource<Resource> = {
  content?: Resource[]
  page: {
    number: number // Page number
    totalElements: number
    size: number
    totalPages: number
  }
}

export enum MessageProvider {
  AWS = 'AWS',
  SENDGRID = 'SENDGRID',
  TWILIO = 'TWILIO',
  LETTER_X_PRESS = 'LETTER_X_PRESS',
}

export enum MessageType {
  MAIL = 'MAIL',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
  LETTER = 'LETTER',
}

export enum MessageState {
  SENT = 'SENT',
  NOT_SENT_BLACKLISTED = 'NOT_SENT_BLACKLISTED',
  NOT_SENT_SANDBOX_ENABLED = 'NOT_SENT_SANDBOX_ENABLED',
  NOT_SENT_ERROR = 'NOT_SENT_ERROR',
  NOT_SENT_OVER_LIMIT = 'NOT_SENT_OVER_LIMIT',
  NOT_SENT_OUT_OF_REGION = 'NOT_SENT_OUT_OF_REGION',
  NOT_SENT_VALIDATION_ERROR = 'NOT_SENT_VALIDATION_ERROR',
  NOT_SENT_UNDELIVERED_ERROR = 'NOT_SENT_UNDELIVERED_ERROR',
  SENT_DELIVERED = 'SENT_DELIVERED',
  SENT_RECEIVED = 'SENT_RECEIVED',
  NOT_SENT_FAILED_ERROR = 'NOT_SENT_FAILED_ERROR',
  NOT_SENT_PARTIALLY_DELIVERED_ERROR = 'NOT_SENT_PARTIALLY_DELIVERED_ERROR',
}

export type MessageLog = {
  createdAt: string
  content: string
  internalLog?: string
  internalId: string
  originServiceName?: string
  providerCmdJson: string
  messageProvider: MessageProvider
  messageType: MessageType
  topic: string
  sentAt: string
  target?: string
  state?: MessageState
  clinicReferenceId: string
  createdByHuman: boolean
  originalMessageJson?: string
  patientReferenceId?: string | null
}

export type UseQueryInput = false | [string, any] | (() => false | [string, any]) | null | undefined

export type PagedMessageLogs = FlatPagedResource<MessageLog>
export type PagedAppointments = FlatPagedResource<AppointmentInternal>
export type PagedBookings = PagedResource<Booking>
export type PagedCustomerEventResource = PagedResource<CustomerEventResource>
export type PagedCustomers = PagedResource<CustomerResource>

export enum CustomerEmailAfterBooking {
  NONE = 'NONE',
  ON_SUBMITTED = 'ON_SUBMITTED',
  ON_CLINIC_ACK = 'ON_CLINIC_ACK',
}

export interface PhoneDevice {
  clinicReferenceId: string
  name: string
  referenceId: string
  phoneNumber: string
  online: boolean
  externalId: string
  ringOnIncomingCalls: boolean
}

export type PagedPhoneDevice = PagedResource<PhoneDevice>

export type PhonesUpdateRequest = {
  phoneReferenceIds: string[]
  ringingPhoneReferenceIds: string[] | null
}

export enum CostPlanStatus {
  UNDEFINED = 'UNDEFINED',
  CREATED = 'CREATED',
  PRINTED = 'PRINTED',
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
  DISCARDED = 'DISCARDED',
  ACCOMPLISHED = 'ACCOMPLISHED',
  TO_REVISE = 'TO_REVISE',
  INSTANT_BILLING = 'INSTANT_BILLING',
  CHARGED = 'CHARGED',
}

export enum CostPlanSubStatus {
  UNDEFINED = 'UNDEFINED',
  CREATED = 'CREATED',
  CREATED_IN_PROGRESS = 'CREATED_IN_PROGRESS',
  CREATED_TO_CHECK = 'CREATED_TO_CHECK',
  CREATED_CHECKED = 'CREATED_CHECKED',
  CREATED_AT_PATIENT = 'CREATED_AT_PATIENT',
  CREATED_AT_EXPERT = 'CREATED_AT_EXPERT',
  PRINTED_AT_INSURANCE = 'PRINTED_AT_INSURANCE',
  DECLINED = 'DECLINED',
  DECLINED_BY_INSURANCE = 'DECLINED_BY_INSURANCE',
  DECLINED_BY_PATIENT = 'DECLINED_BY_PATIENT',
  DECLINED_BY_EXPERT = 'DECLINED_BY_EXPERT',
  ACCEPTED = 'ACCEPTED',
  ACCEPTED_NO_FOLLOW_UP_APPOINTMENT = 'ACCEPTED_NO_FOLLOW_UP_APPOINTMENT',
  ACCEPTED_WITH_FOLLOW_UP_APPOINTMENT = 'ACCEPTED_WITH_FOLLOW_UP_APPOINTMENT',
  ACCEPTED_IN_PROGRESS = 'ACCEPTED_IN_PROGRESS',
  ACCOMPLISHED = 'ACCOMPLISHED',
  CHARGED = 'CHARGED',
  DISCARDED = 'DISCARDED',
  DISCARDED_DIFFERENT_TREATMENT_DONE = 'DISCARDED_DIFFERENT_TREATMENT_DONE',
  DISCARDED_COST_PLAN_TOO_EXPENSIVE = 'DISCARDED_COST_PLAN_TOO_EXPENSIVE',
  DISCARDED_COST_PLAN_TOO_DIFFICULT_TO_UNDERSTAND = 'DISCARDED_COST_PLAN_TOO_DIFFICULT_TO_UNDERSTAND',
  DISCARDED_PATIENT_AWAITS_CALL = 'DISCARDED_PATIENT_AWAITS_CALL',
  DISCARDED_WRONG_SOLUTION = 'DISCARDED_WRONG_SOLUTION',
  DISCARDED_PATIENT_FEELS_PATRONIZED = 'DISCARDED_PATIENT_FEELS_PATRONIZED',
  DISCARDED_UNSYMPATHETIC_PRACTITIONER = 'DISCARDED_UNSYMPATHETIC_PRACTITIONER',
  DISCARDED_UNCOMFORTABLE_CLINIC_SURROUNDING = 'DISCARDED_UNCOMFORTABLE_CLINIC_SURROUNDING',
  DISCARDED_SWAP_DENTIST_WITHOUT_GIVEN_REASON = 'DISCARDED_SWAP_DENTIST_WITHOUT_GIVEN_REASON',
  DISCARDED_WITHOUT_REASON = 'DISCARDED_WITHOUT_REASON',
}

export enum CostPlanType {
  UNKNOWN = 'UNKNOWN',
  PRIVATE = 'PRIVATE',
  KFO = 'KFO',
  ZE = 'ZE',
  KBR = 'KBR',
  PAR = 'PAR',
  IMP = 'IMP',
  FAL = 'FAL',
  ZMK = 'ZMK',
  GTR = 'GTR',
  KASSE_PAR_STATUS = 'KASSE_PAR_STATUS',
  EBZ_ZE = 'EBZ_ZE',
  EBZ_KGL = 'EBZ_KGL',
}

export enum OverBookingMode {
  None = 'None',
  Secondary = 'Secondary',
  Primary = 'Primary',
  Both = 'Both',
}

export type OverBookingData = {
  minPractitionerLevel: PractitionerLevel
  secondaryTreatmentMaxDurationMin?: number
  secondaryTreatmentOffsetMin?: number
}

export enum AdapterSyncResourceType {
  ALL = 'ALL',
  ON_BOARDING = 'ON_BOARDING',
  PRACTITIONER = 'EMPLOYEE',
  PATIENT = 'PATIENT',
  APPOINTMENT = 'APPOINTMENT',
  TREATMENT = 'TREATMENT',
  TREATMENT_GROUP = 'TREATMENT_GROUP',
  REVENUE = 'REVENUE',
  COST_PLAN = 'COST_PLAN',
  ROOM = 'ROOM',
  LEGAL_DOCUMENTS = 'LEGAL_DOCUMENTS',
  MEDICAL_RECORD = 'MEDICAL_RECORD',
  USERS_CLAIRE = 'USERS_CLAIRE',
  DOCUMENTS = 'DOCUMENTS',
  FAILED_BOOKINGS = 'FAILED_BOOKINGS',
  OPEN_APPOINTMENTS = 'OPEN_APPOINTMENTS',
  MEDICAL_RECORD_JAW_FINDINGS = 'MEDICAL_RECORD_JAW_FINDINGS',
  MEDICAL_RECORD_NOTES = 'MEDICAL_RECORD_NOTES',
  MEDICAL_RECORD_SERVICE_GROUPS = 'MEDICAL_RECORD_SERVICE_GROUPS',
  MEDICAL_RECORD_LAB_SERVICE_GROUPS = 'MEDICAL_RECORD_LAB_SERVICE_GROUPS',
  MEDICAL_RECORD_PSI_DIAGNOSIS = 'MEDICAL_RECORD_PSI_DIAGNOSIS',
}

/*
 * Be aware!
 * Backend uses Locale.getISOLanguages() from Java standard library
 * and under the hood it uses IETF standard (https://en.wikipedia.org/wiki/IETF_language_tag) for language tags - which is fine,
 * but these Jarvis languages are mostly used to display flags for practitioners,
 * and most flag libraries use ISO 3166 country codes (https://en.wikipedia.org/wiki/ISO_3166-1).
 * In most cases these two codes overlap, but technically language code != country code and sometimes (especially for countries with > 1 language spoken) it is a problem.
 * For example: We use 'jp' code for Japan (country), but for Japanese (lang) we need to use 'ja' code.
 * */
export enum Language {
  'ru' = 'Russian',
  'ku' = 'Kurdish',
  'tr' = 'Turkish',
  'en' = 'English',
  'fa' = 'Persian',
  'ar' = 'Arabic',
  'hr' = 'Croatian',
  'bs' = 'Bosnian',
  'sr' = 'Serbian',
  'de' = 'German',
  'el' = 'Greek',
  'hu' = 'Hungarian',
  'ro' = 'Romanian',
  'pl' = 'Polish',
  'es' = 'Spanish',
  'it' = 'Italian',
  'fr' = 'French',
  'pt' = 'Portuguese',
  'no' = 'Norwegian',
  'vi' = 'Vietnamese',
  'sq' = 'Albanian',
  'ti' = 'Tigrinya',
  'hi' = 'Hindi',
  'bg' = 'Bulgarian',
  'cs' = 'Czech',
  'tl' = 'Tagalog',
  'lv' = 'Latvian',
  'se' = 'Swedish',
  'kr' = 'Korean',
  'fi' = 'Finnish',
  'da' = 'Danish',
  'nl' = 'Dutch',
  'zh' = 'Chinese',
  'ja' = 'Japanese',
  'af' = 'Afghan',
  'mo' = 'Moroccan',
  'sw' = 'Swahili',
  'id' = 'Indonesian',
  'uk' = 'Ukrainian',
  'he' = 'Hebrew',
}

export type MdEditorMode = 'preview' | 'write'

export enum ClinicSoftwareType {
  SOLUTIO = 'SOLUTIO',
  DAMPSOFT = 'DAMPSOFT',
  Z1 = 'Z1',
  DOC_CIRRUS = 'DOC_CIRRUS',
  CLAIRE = 'CLAIRE',
  UNKNOWN = 'UNKNOWN',
  NONE = 'NONE',
}

export enum ClinicType {
  DENTIST = 'DENTIST',
  GP = 'GP',
  GYNAECOLOGIST = 'GYNAECOLOGIST',
  UROLOGIST = 'UROLOGIST',

  SURGICAL = 'SURGICAL',
  DERMATOLOGIST = 'DERMATOLOGIST',
  PSYCHIATRIST = 'PSYCHIATRIST',
  OPTOMETRIST = 'OPTOMETRIST',
  PEDIATRIST = 'PEDIATRIST',

  UNKNOWN = 'UNKNOWN',
}

export interface Organization {
  referenceId: string
  name: string
  url: string
  logo: string
}

export enum CVEntryType {
  PROFESSIONAL_EXPERIENCE = 'PROFESSIONAL_EXPERIENCE',
  EDUCATION = 'EDUCATION',
  CERTIFICATE = 'CERTIFICATE',
}

export interface CVEntry {
  title: string
  description: string | null
  type: CVEntryType
  startDate: string | null
  endDate: string | null
  originalIndex?: number
}

export interface SharedClinicTreatmentData {
  clinicType: ClinicType
  referenceId: string
  title?: string
  externalTitle?: string
  deleted?: boolean

  tenantReferenceId?: Nullable<string>
  tenant?: Nullable<Tenant>
  requiredPractitionerRoles?: PractitionerRole[]
  treatmentPurpose?: TreatmentPurposeBasic
  translations?: TreatmentTranslations
}

export interface TreatmentBundleTranslations {
  titleKey?: string
  descriptionKey?: string
  bundleCategoryKey?: string
}

export interface GeneralClinicTreatment extends SharedClinicTreatmentData {
  enabledReminder: boolean
  links: string[]
  usedForRecall: boolean
  overBookingMode: OverBookingMode
  overBookingData?: OverBookingData | null
  primaryOverBookingData?: OverBookingData | null // Deprecated - to be removed after https://dentalux.atlassian.net/browse/D21-30868
  generalTreatmentGroupReferenceId?: string
  canUpdateClinicType?: boolean
  patientExperienceFlows?: PatientExperienceFlow[]

  bookableByPatient?: boolean
  treatmentCategory?: string

  shadowBookingData?: ShadowBookingData

  durationInMin?: number
}

export interface SaveGeneralClinicTreatment extends Omit<GeneralClinicTreatment, 'treatmentPurpose'> {
  treatmentPurposeReferenceId: string
}

export interface TreatmentPurposeBasic {
  referenceId: string
  contentfulKey: string
}

export interface ShadowBookingData {
  enabled: boolean
  threshold: number
}

export interface TreatmentTranslations {
  titleKey?: string
}

export type GeneralClinicTreatmentFilter = {
  title?: string
  overbookingMode?: OverBookingMode
  reminderEnabled?: boolean
  bookableByPatient?: boolean
  requiredPractitionerRoles?: PractitionerRole[]
  clinicType?: ClinicType
  tenantReferenceId?: string
}

export enum Mode {
  CREATE,
  UPDATE,
}

export type ModeProps = { mode: Mode }

export type ClinicSyncResourceType = 'TREATMENT_GROUP' | 'TREATMENT'

export enum SurveyQuestionType {
  DIRECT = 'DIRECT',
  CONDITIONAL = 'CONDITIONAL',
}

export enum SurveyPossibleAnswerType {
  FREE_TEXT = 'FREE_TEXT',
  RADIO_BUTTONS = 'RADIO_BUTTONS',
  MULTI_SELECT = 'MULTI_SELECT',
}

export enum SurveyContactType {
  EMAIL = 'EMAIL',
  OTHER = 'OTHER',
}

export type SurveyContact = {
  recipient: string
  type: SurveyContactType
  alsoContactPractitioner?: boolean
}

export type SurveyAnswerReaction = {
  type: ReactionType
  question?: SurveyQuestion
  text?: { reactionText: string }
  contacts?: SurveyContact[]
}

export type SurveyPossibleAnswer = {
  referenceId: string
  text: string
  type: SurveyPossibleAnswerType
  deleted: boolean
  reactions: SurveyAnswerReaction[]
}

export type SurveyQuestion = {
  referenceId: string
  type: SurveyQuestionType
  question: string
  infoText?: string
  possibleAnswers: SurveyPossibleAnswer[]
}

export type Survey = {
  referenceId: string
  title: string
  infoText?: string
  outroText?: string
  externalTriggerReferenceId?: string
  version: number
  deleted: boolean
  questions: SurveyQuestion[]
  treatmentCodes: string[]
}

export type SurveyContactForm = SurveyContact

export enum ReactionType {
  QUESTION = 'question',
  TEXT = 'text',
  CONTACTS = 'contacts',
}

export type ReactionForm = {
  type: ReactionType
  question?: QuestionForm
  text?: { reactionText: string }
  contacts?: SurveyContactForm[]
}

export type AnswerForm = {
  // The empty string (`''`) possibiltiy is needed because the form must start
  // without an initial value for the type
  type: SurveyPossibleAnswerType | ''
  text: string
  reactions?: ReactionForm[]
}

export type QuestionForm = {
  question: string
  infoText?: string
  possibleAnswers: AnswerForm[]
}

export type SurveyForm = {
  title: string
  infoText?: string
  outroText?: string
  questions: QuestionForm[]
  treatmentCodes: string[]
}

export interface AdsSchema {
  schemaType: SchemaType
  clusterTypes: string[]
  language: string
  templateBundle: TemplateBundle
}

export enum SchemaType {
  SHOPIFY = 'SHOPIFY',
  DENTIST = 'DENTIST',
  CUSTOM = 'CUSTOM',
}

export interface TemplateBundle {
  uuid: string
  language: string
  long: TextAd
  middle: TextAd
  short: TextAd
}

export interface TextAd {
  description1: string
  description2: string
  headline1: string
  headline2: string
  headline3: string
}

export interface ClusterInformation {
  keyword: string
  clusterType: string
}

/**
 * A View is a predefined permission from Hades which is part of the JWT token. In order to make Jarvis aware such
 * application views we have to define them here and make use of them while respecting.
 *
 * E.g.: For Wondermeds related view permission we would like to hide certain menu points in Jarvis.
 */
export enum View {
  JARVIS_MESSAGE_LOG = 'JARVIS_MESSAGE_LOG',
  JARVIS_BLACKLIST = 'JARVIS_BLACKLIST',
  JARVIS_CLINIC = 'JARVIS_CLINIC',
  JARVIS_GENERAL_TREATMENT_BUNDLE = 'JARVIS_GENERAL_TREATMENT_BUNDLE',
  JARVIS_TOUCHPOINT = 'JARVIS_TOUCHPOINT',
  JARVIS_AVAILY_BOOKING = 'JARVIS_AVAILY_BOOKING',
  JARVIS_ECHO_NET = 'JARVIS_ECHO_NET',
  JARVIS_PRACTITIONER_CONTRACT_INFO = 'JARVIS_PRACTITIONER_CONTRACT_INFO',
  JARVIS_PERSON_MERGER = 'JARVIS_PERSON_MERGER',

  WONDERMEDS_CLUSTER_INFORMATION = 'WONDERMEDS_CLUSTER_INFORMATION',
  WONDERMEDS_SCHEMAS = 'WONDERMEDS_SCHEMAS',
  WONDERMEDS_CLINIC_CONFIGURATION = 'WONDERMEDS_CLINIC_CONFIGURATION',
}

export interface ContractFormData {
  validFrom: Date
  baseSalary: number
  guaranteedPayout: number
  guaranteedPayoutMonths: number
  threshold: number
  baseRevenueShare: number
  npsBonus: boolean
  teamThresholdBonus: number
  teamRevenueShare: number
  discountRate: number
  capTeamBonus?: number
  bonusEntitlement: boolean
}

export interface ContractData extends ContractFormData {
  referenceId: string
  editable: boolean
  current: boolean
}

export interface ClinicConnectionDetailsResult {
  tenantId?: string
  locationId?: string
  hasCredentials: boolean
}

export interface ClinicConnectionDetailsForm {
  username: string
  password: string
  socketPassword: string
  tenantId: string
  locationId: string
}

export interface EmployeeToSelect {
  practitionerReferenceId: string
  firstName?: string
  lastName?: string
  active: boolean
}

export type PhoneSetupLanguage = 'DE_DE' | 'EN_US'

export interface PhoneSetupForm {
  textNoClinic?: string
  textNoPhoneAssigned?: string
  textAllAreBusy?: string
  textNoOneIsThere?: string
  waitOnIncomingCallsToAnswerSec?: number
  enabled?: boolean
}

export interface ClinicPhoneSetupForm extends PhoneSetupForm {
  textNoPhoneAssignedOverwritten: boolean
  textAllAreBusyOverwritten: boolean
  textNoOneIsThereOverwritten: boolean
  waitOnIncomingCallsToAnswerSecOverwritten: boolean
}

export interface PhoneSetup extends PhoneSetupForm {
  languageType: PhoneSetupLanguage
  clinicReferenceId?: string
  overriddenClinicOpeningHours?: WorkingHour[]
}

export interface PhoneSetupResult extends PhoneSetup {
  referenceId: string
}

export enum TimeOffsetSelector {
  AFTER_PREVIOUS_STEP = 'AFTER_PREVIOUS_STEP',
  BEFORE_PREVIOUS_STEP = 'BEFORE_PREVIOUS_STEP',
  OVERLAPPING_AT_THE_BEGINNING = 'OVERLAPPING_AT_THE_BEGINNING',
  OVERLAPPING_AT_THE_END = 'OVERLAPPING_AT_THE_END',
  SKIP_REMAINING_STEPS = 'SKIP_REMAINING_STEPS',
}

export interface PatientExperienceFlow {
  order: number
  description: string
  headline: string
  headlineContentfulKey: string
  descriptionContentfulKey: string
}

export enum TouchpointFilterType {
  HAS_FUNNEL_STAGE_OF = 'HAS_FUNNEL_STAGE_OF',
  LAST_APPOINTMENT_HAS_STATE_OF = 'LAST_APPOINTMENT_HAS_STATE_OF',
  NEXT_APPOINTMENT_HAS_STATE_OF = 'NEXT_APPOINTMENT_HAS_STATE_OF',
  COST_PLAN_HAS_TYPE = 'COST_PLAN_HAS_TYPE',
  LATEST_COST_PLAN_NEWER_THAN_DAYS = 'LATEST_COST_PLAN_NEWER_THAN_DAYS',
  LATEST_COST_PLAN_OLDER_THAN_DAYS = 'LATEST_COST_PLAN_OLDER_THAN_DAYS',
  HAS_ALL_APPOINTMENTS_IN_THE_FUTURE = 'HAS_ALL_APPOINTMENTS_IN_THE_FUTURE',
  HAS_APPOINTMENT_AFTER = 'HAS_APPOINTMENT_AFTER',
  HAS_APPOINTMENT_BEFORE = 'HAS_APPOINTMENT_BEFORE',
  HAS_APPOINTMENTS_IN_THE_FUTURE = 'HAS_APPOINTMENTS_IN_THE_FUTURE',
  CLINIC_TYPE_IS = 'CLINIC_TYPE_IS',
  BELONGS_TO_CLINIC = 'BELONGS_TO_CLINIC',
  IN_CLINIC = 'IN_CLINIC',
  IN_CLINIC_TYPE = 'IN_CLINIC_TYPE',
  COST_PLAN_DESCRIPTION_DOES_NOT_CONTAIN_ANY_OF_THE_GIVEN_WORDS = 'COST_PLAN_DESCRIPTION_DOES_NOT_CONTAIN_ANY_OF_THE_GIVEN_WORDS',
  COST_PLAN_STATUS_SUB_STATUS_NOT_CHANGED_SINCE_DAYS = 'COST_PLAN_STATUS_SUB_STATUS_NOT_CHANGED_SINCE_DAYS',
  COST_PLAN_UPDATE_NOT_OLDER_THAN_DAYS = 'COST_PLAN_UPDATE_NOT_OLDER_THAN_DAYS',
  DOES_NOT_CONTAIN_STRING_IN_ANY_APPOINTMENT_TITLE = 'DOES_NOT_CONTAIN_STRING_IN_ANY_APPOINTMENT_TITLE',
  REQUIRES_ANAMNESIS = 'REQUIRES_ANAMNESIS',
  HAS_POSTAL_ADDRESS = 'HAS_POSTAL_ADDRESS',
  HAS_APPOINTMENTS_TODAY = 'HAS_APPOINTMENTS_TODAY',
  HAS_APPOINTMENTS_WITH = 'HAS_APPOINTMENTS_WITH',
  HAS_APPOINTMENTS = 'HAS_APPOINTMENTS',
  IS_CREATED_AFTER = 'IS_CREATED_AFTER',
  IS_CREATED_BEFORE = 'IS_CREATED_BEFORE',
  HAS_COST_PLANS = 'HAS_COST_PLANS',
  HAS_EMAIL = 'HAS_EMAIL',
  HAS_NO_APPOINTMENTS_AFTER_TODAY = 'HAS_NO_APPOINTMENTS_AFTER_TODAY',
  HAS_NO_HAPPY_RATING_SINCE_DAYS = 'HAS_NO_HAPPY_RATING_SINCE_DAYS',
  HAS_PHONE_NUMBER = 'HAS_PHONE_NUMBER',
  HAS_VISITED_APPOINTMENT_YESTERDAY = 'HAS_VISITED_APPOINTMENT_YESTERDAY',
  HAS_INSURANCE_TYPE = 'HAS_INSURANCE_TYPE',
  IS_OLDER_THAN = 'IS_OLDER_THAN',
  LATEST_COST_PLAN_IN_STATES = 'LATEST_COST_PLAN_IN_STATES',
  LATEST_COST_PLAN_IN_SUB_STATES = 'LATEST_COST_PLAN_IN_SUB_STATES',
  IN_COUNTRY = 'IN_COUNTRY',
  IS_ELIGIBLE_FOR_PERIODONTAL_TREATMENT = 'IS_ELIGIBLE_FOR_PERIODONTAL_TREATMENT',
  REFERENCED_BY_EXTERNAL_TRIGGER_EVENT = 'REFERENCED_BY_EXTERNAL_TRIGGER_EVENT',
  HAS_NOT_VISITED_CLINIC_SINCE_DAYS = 'HAS_NOT_VISITED_CLINIC_SINCE_DAYS',
  PATIENT_REFERENCE_ID_DOES_NOT_EQUAL_TO = 'PATIENT_REFERENCE_ID_DOES_NOT_EQUAL_TO',
}

export interface MailUnsubscribeGroup {
  name: string
  externalId: string
}

export interface EmployeesMergeForm {
  employeeToSave: string // referenceId -> uuid
  employeeToMergeAndDelete: string // referenceId -> uuid
}

export type GapFillerGeneralTreatment = {
  externalTitle: string
  referenceId: string
}

export type GapFillerConfig = {
  abTestPhoneContactIsEnabled: boolean
  clinicsEnabledForGapFilling: { referenceId: string }[]
  contactTextTemplateSMS: string
  daysToLookAheadOnGapDetection: number
  enabled: boolean
  enabledForAllClinics: boolean
  followUpTimeMapping: { hours: number; periodInMinutes: number }[]
  generalTreatmentsEligibleForMatching: string[]
  maximumDaysToFetchAppointments: number
  minimumAgeForMatchingPatient: number
  minimumDaysToFetchAppointments: number
  numberOfPatientsToContactPerGap: number
  operationTimes: { weekDays: WeekDay[]; startTime: string; endTime: string }
  practitionerRolesConsideredInGapFilling: PractitionerRole[]
  sandbox: boolean
  shouldUseEmailAsContactMedium: boolean
  shouldUsePhoneAsContactMedium: boolean
}

export interface BundleCategory {
  referenceId?: string
  clinicType: ClinicType
  contentfulKey: string
  iconUrl: string
  order: number
  // drag and drop lib requires all items to have key(id) specified,
  // so we need to fake generate it for newly created (not pushed yet) entries
  fallbackId?: string
}

export interface GeneralTreatmentPurpose {
  referenceId?: string
  clinicType: ClinicType
  contentfulKey: string
  isUniqueForBundlePerClinic: boolean
  order: number
  internalName: string
  // drag and drop lib requires all items to have key(id) specified,
  // so we need to fake generate it for newly created (not pushed yet) entries
  fallbackId?: string
}

export enum RecommendationTypeEnum {
  TREATMENT_BUNDLE = 'TREATMENT_BUNDLE',
  COST_PLAN_BASED_TREATMENT_BUNDLE = 'COST_PLAN_BASED_TREATMENT_BUNDLE',
}

export enum RecommendationFrequencyUnit {
  HOURS = 'HOURS',
  HALF_DAYS = 'HALF_DAYS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
  DECADES = 'DECADES',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  DIVERSE = 'DIVERSE',
}

export enum DentalPsiCriteriaType {
  EXTREME = 'EXTREME',
  HIGH = 'HIGH',
}

export enum CariesType {
  INITIAL_CARIES = 'INITIAL_CARIES',
  CARIES = 'CARIES',
  NONE = 'NONE',
}

export type RecommendationFrequency = {
  unit: RecommendationFrequencyUnit
  count: number
}

export type AgeCriteria = {
  ageFrom: number
  ageTo: number
}

export type GenderCriteria = {
  genderType: Gender
}

export type DentalPsiCriteria = {
  type: DentalPsiCriteriaType
  referenceId: string
}

export type CariesCriteria = {
  type: CariesType
  shouldHaveThisCariesType: boolean
  referenceId: string
}

export type AppointmentCriteria = {
  referenceId: string
  numberOfMonthsBeforeNowAppointmentsStart: number
  treatmentPurposeReferenceId: string
}

export type OpenAppointmentCriteria = {
  referenceId: string
  notOlderThanMonths: number
  treatmentPurposeReferenceId: string
}

export type CostPlanCriteria = {
  types: Nullable<CostPlanType[]>
  status: Nullable<CostPlanStatus>
  subStatuses: Nullable<CostPlanSubStatus[]>
  maxNumberOfMonthsSinceLastCostPlanStatusChange: Nullable<number>
}

export type AnaWishesCriteriaForm = {
  afraidOfDentist?: boolean | string
  suffersFromBadBreath?: boolean | string
  toothGrindingDuringSleep?: boolean | string
  snores?: boolean | string
  hasAmalgamFillingToReplace?: boolean | string
  satisfiedWithFillingColor?: boolean | string
  hasDentalBridge?: boolean | string
  toothacheFrequency?: Frequency[]
  toothColorSatisfaction?: Satisfaction[]
  toothPositionSatisfaction?: Satisfaction[]
}

export type AnaWishesCriteriaFieldData = {
  label: string
  type: 'boolean' | 'frequency' | 'satisfaction'
}

export enum Frequency {
  NEVER = 'NEVER',
  RARELY = 'RARELY',
  SOMETIMES = 'SOMETIMES',
  OFTEN = 'OFTEN',
  VERY_OFTEN = 'VERY_OFTEN',
}

export enum Satisfaction {
  VERY_DISSATISFIED = 'VERY_DISSATISFIED',
  DISSATISFIED = 'DISSATISFIED',
  SOMEWHAT_DISSATISFIED = 'SOMEWHAT_DISSATISFIED',
  SATISFIED = 'SATISFIED',
  VERY_SATISFIED = 'VERY_SATISFIED',
}

export type PriorityCriteria = {
  recommendationsThatBlockThisType: Nullable<string[]>
}

export type RecommendationType = {
  referenceId: string
  type: RecommendationTypeEnum
  enabled: boolean
  name: string
  category: RecommendationCategory
  costTranslationKey: string
  treatmentPurposeReferenceId: string
  speciality: RecommendationSpeciality
  shortDescriptionTranslationKey: string
  purpose: RecommendationPurpose
  longDescriptionTranslationKey: string
  frequency: Nullable<RecommendationFrequency>
  titleTranslationKey: string
  ageCriteria: AgeCriteria[]
  genderCriteria: GenderCriteria[]
  cariesCriteria: CariesCriteria[]
  dentalPsiCriteria: DentalPsiCriteria[]
  appointmentCriteria: AppointmentCriteria[]
  openAppointmentCriteria: OpenAppointmentCriteria[]
  costPlanCriteria: CostPlanCriteria[]
  anaWishesCriteria: AnaWishesCriteriaForm[]
  priorityCriteria: PriorityCriteria[]
  displayOrder: number
  deleted: boolean
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export interface ChangelogItem {
  value: string | Record<string, any>
  changedAt: string
  changedBy: string
}

export interface ClinicMonitoringState {
  resourceId: {
    clinicReferenceId: string
  }
  state: MonitoringSubState
  lastChangedAt: string
  pmsAdapter: {
    connection: ClinicMonitoringConnectionState
    software: ClinicMonitoringSoftwareState
  }
  xrayAdapter: {
    connection: ClinicMonitoringConnectionState
    software: ClinicMonitoringSoftwareState
  }
  tiAdapter: {
    connection: ClinicMonitoringConnectionState
    software: ClinicMonitoringSoftwareState
    tiConnection: ClinicMonitoringConnectionState
    easyTiSoftware: ClinicMonitoringSoftwareState
  }
  device: {
    connection: ClinicMonitoringConnectionState
  }
}

export interface ClinicMonitoringConnectionState {
  state: MonitoringSubState
  lastChangedAt: string
}

export interface ClinicMonitoringSoftwareState {
  state: MonitoringSubState
  lastChangedAt: string
}

export enum MonitoringSubState {
  GOOD = 'GOOD',
  BAD = 'BAD',
  DISABLED = 'DISABLED',
  UNKNOWN = 'UNKNOWN',
}

export interface ClinicWithMonitoring extends Clinic {
  monitoringState: ClinicMonitoringState
}

export interface MonitoringSettingsResponse {
  clinicReferenceId: string
  pmsAdapter: {
    connection: 'ENABLED' | 'DISABLED'
    software: 'ENABLED' | 'DISABLED'
  }
  xrayAdapter: {
    connection: 'ENABLED' | 'DISABLED'
    software: 'ENABLED' | 'DISABLED'
  }
  tiAdapter: {
    connection: 'ENABLED' | 'DISABLED'
    software: 'ENABLED' | 'DISABLED'
    tiConnection: 'ENABLED' | 'DISABLED'
    easyTiSoftware: 'ENABLED' | 'DISABLED'
  }
  device: {
    connection: 'ENABLED' | 'DISABLED'
  }
}

export type MonitoringSettingsForm = Omit<MonitoringSettingsResponse, 'clinicReferenceId'>

export enum PractitionerSpecialisation {
  ALIGNER_THERAPIE = 'ALIGNER_THERAPIE',
  PEDIATRIC_DENTISTRY = 'PEDIATRIC_DENTISTRY',
  ENDODONTOLOGY = 'ENDODONTOLOGY',
  PERIODONTOLOGY = 'PERIODONTOLOGY',
  AESTHETIC_DENTISTRY = 'AESTHETIC_DENTISTRY',
  DENTURES = 'DENTURES',
  CMD = 'CMD',
  CEREC = 'CEREC',
  ORAL_SURGERY = 'ORAL_SURGERY',
  IMPLANTOLOGY = 'IMPLANTOLOGY',
  GERIATRIC_DENTISTRY = 'GERIATRIC_DENTISTRY',
  MICROBIOLOGICAL_THERAPY = 'MICROBIOLOGICAL_THERAPY',
  TEETH_PRESERVATION = 'TEETH_PRESERVATION',
  LAUGHING_GAS = 'LAUGHING_GAS',
  BOTOX = 'BOTOX',
  ORTHODONTICS = 'ORTHODONTICS',
  SPORT_DENTISTRY = 'SPORT_DENTISTRY',
  ANXIETY = 'ANXIETY',
}

export interface PersonSmallResult {
  referenceId: string
  firstName: string
  lastName: string
  email: string | null
  phoneNumber: string | null
  birthday: string
  gender: string | null
  deleted: boolean
  patientsCount: number
  healthAssistantUserAccountReferenceId: string | null
  requestedPatientReferenceId: string | null
}

export interface FetchPersonsToMergeForm {
  firstPatientId: string
  secondPatientId: string
}

export type FetchPersonToMergeData = {
  firstPerson?: PersonSmallResult
  secondPerson?: PersonSmallResult
  firstPersonError?: string
  secondPersonError?: string
}

export interface MergePersonsForm {
  winningPerson: PersonSmallResult
  losingPerson: PersonSmallResult
}

export interface FetchPatientByExternalIdForm {
  externalId: string
  clinicReferenceId: string
}

export interface UniversalSearchForm {
  search?: string
  tenantReferenceId?: string[]
  clinicReferenceId?: string[]
  clinicType?: ClinicType[]
}
