import { useCredentials } from '@dentalux/security'
import { View } from '../types'
import { useCallback } from 'react'
import { User } from '@dentalux/security/lib/types'

const useUserAccess = () => {
  // for some reason `useCredentials().authentication` has `any` type
  const user = useCredentials().authentication as User

  const hasAccessToTheView = useCallback(
    (view?: View) => {
      if (!view) return true

      return (
        user?.roles
          ?.flatMap((rolesForClinic) => rolesForClinic.role.applicationPermissions)
          ?.some((grantedPermission) => `${grantedPermission.application}_${grantedPermission.view}` === view) ?? false
      )
    },
    [user]
  )

  return { hasAccessToTheView }
}

export default useUserAccess
