import React from 'react'
import lazyWithRetry from './helpers/lazyWithRetry'
import { View } from './types'

const GeneralTreatmentBundles = lazyWithRetry(() => import('./pages/GeneralTreatmentBundles'))
const GeneralTreatmentBundle = lazyWithRetry(() => import('./pages/GeneralTreatmentBundle'))
const Users = lazyWithRetry(() => import('./pages/Users'))
const NewUser = lazyWithRetry(() => import('./pages/User/NewUser'))
const User = lazyWithRetry(() => import('./pages/User'))
const NewClinic = lazyWithRetry(() => import('./pages/Clinic/NewClinic'))
const Clinics = lazyWithRetry(() => import('./pages/Clinics'))
const Clinic = lazyWithRetry(() => import('./pages/Clinic'))
const ClinicRedesign = lazyWithRetry(() => import('./pages/ClinicRedesign'))
const Practitioners = lazyWithRetry(() => import('./pages/Practitioners'))
const Practitioner = lazyWithRetry(() => import('./pages/Practitioner'))
const Treatments = lazyWithRetry(() => import('./pages/Treatments'))
const Treatment = lazyWithRetry(() => import('./pages/Treatment'))
const Devices = lazyWithRetry(() => import('./pages/Devices'))
const Device = lazyWithRetry(() => import('./pages/Device'))
const Touchpoints = lazyWithRetry(() => import('./pages/Touchpoints'))
const Touchpoint = lazyWithRetry(() => import('./pages/Touchpoint'))
const TouchpointsHistory = lazyWithRetry(() => import('./pages/TouchpointsHistory'))
const Template = lazyWithRetry(() => import('./pages/Template'))
const Templates = lazyWithRetry(() => import('./pages/Templates'))
const CustomerEvents = lazyWithRetry(() => import('./pages/CustomerEvents'))
const Environments = lazyWithRetry(() => import('./pages/Environments'))
const Rooms = lazyWithRetry(() => import('./pages/Rooms'))
const Room = lazyWithRetry(() => import('./pages/Room'))
const Appointments = lazyWithRetry(() => import('./pages/Appointments'))
const ClinicTreatments = lazyWithRetry(() => import('./pages/ClinicTreatments'))
const TreatmentTypes = lazyWithRetry(() => import('./pages/TreatmentTypes'))
const ClinicTreatment = lazyWithRetry(() => import('./pages/ClinicTreatment'))
const Blacklist = lazyWithRetry(() => import('./pages/Blacklist/List'))
const MessageLogs = lazyWithRetry(() => import('./pages/MessageLogs/List'))
const GeneralClinicTreatments = lazyWithRetry(() => import('./pages/GeneralClinicTreatments'))
const GeneralTreatmentPurposes = lazyWithRetry(() => import('./pages/GeneralTreatmentPurposes'))
const GeneralClinicTreatment = lazyWithRetry(() => import('./pages/GeneralClinicTreatment'))
const GeneralBundleCategories = lazyWithRetry(
  () => import('./pages/GeneralBundleCategories/GeneralBundleCategoriesPage')
)
const FAQsPage = lazyWithRetry(() => import('./pages/FAQsPage'))
const FAQPage = lazyWithRetry(() => import('./pages/FAQPage'))
const SurveyPage = lazyWithRetry(() => import('./pages/Survey'))
const SurveysPage = lazyWithRetry(() => import('./pages/Surveys'))
const ClusterInformationsPage = lazyWithRetry(() => import('./pages/ClusterInformation'))
const SchemasPage = lazyWithRetry(() => import('./pages/AdsSchemas'))
const GeneralPhoneSetup = lazyWithRetry(() => import('./pages/GeneralPhoneSetup'))
const ServiceMonitor = lazyWithRetry(() => import('./pages/ServiceMonitor'))
const ServiceSwagger = lazyWithRetry(() => import('./pages/ServiceSwagger'))
const ClinicPhoneSetup = lazyWithRetry(() => import('./pages/ClinicPhoneSetup'))
const ClinicsOperations = lazyWithRetry(() => import('./pages/ClinicsOperations'))
const GapFillerSettings = lazyWithRetry(() => import('./pages/GapFillerSettings'))
const Recommendation = lazyWithRetry(() => import('./pages/Recommendation'))
const Recommendations = lazyWithRetry(() => import('./pages/Recommendations'))
const PersonMerger = lazyWithRetry(() => import('./pages/PersonMerger'))
const IdFinder = lazyWithRetry(() => import('./pages/IdFinder'))
const UniversalSearch = lazyWithRetry(() => import('./pages/UniversalSearch'))

interface Route {
  path: string
  exact: boolean
  name: string
  view?: View
  component?: React.LazyExoticComponent<React.ComponentType<any>>
}

const routes: Route[] = [
  { path: '/', exact: true, name: 'Home', component: Clinics },
  { path: '/clinics', exact: true, name: 'Clinics', component: Clinics },
  {
    path: '/clinics/new',
    exact: true,
    name: 'New Clinic',
    component: NewClinic,
  },
  { path: '/clinics/:id', exact: true, name: 'Clinic', component: Clinic },

  { path: '/clinics-redesign/:id', exact: true, name: 'Clinic Redesign', component: ClinicRedesign },
  {
    path: '/clinics/:clinicReferenceId/practitioners',
    exact: true,
    name: 'Practitioners',
    component: Practitioners,
  },
  {
    path: '/clinics/:clinicReferenceId/practitioners/:practitionerReferenceId',
    exact: true,
    name: 'Practitioner',
    component: Practitioner,
  },
  {
    path: '/clinics/:clinicReferenceId/employees',
    exact: true,
    name: 'Practitioners',
    component: Practitioners,
  },
  {
    path: '/clinics/:clinicReferenceId/employees/:practitionerReferenceId',
    exact: true,
    name: 'Practitioner',
    component: Practitioner,
  },
  {
    path: '/clinics/:clinicReferenceId/treatments',
    exact: true,
    name: 'Treatments',
    component: Treatments,
  },
  {
    path: '/clinics/:clinicReferenceId/treatments/new',
    exact: true,
    name: 'Create Treatment',
    component: Treatment,
  },
  {
    path: '/clinics/:clinicReferenceId/treatments/:treatmentReferenceId',
    exact: true,
    name: 'Treatment',
    component: Treatment,
  },
  {
    path: '/clinics/:clinicReferenceId/environments',
    exact: true,
    name: 'Clinic Environments',
    component: Environments,
  },
  {
    path: '/clinics/:clinicReferenceId/rooms',
    exact: true,
    name: 'Clinic Rooms',
    component: Rooms,
  },
  {
    path: '/clinics/:clinicReferenceId/rooms/:roomReferenceId',
    exact: true,
    name: 'Room',
    component: Room,
  },
  {
    path: '/clinics/:clinicReferenceId/rooms/:roomReferenceId/:mode',
    exact: true,
    name: 'Room',
    component: Room,
  },
  {
    path: '/clinics/:clinicReferenceId/appointments',
    exact: true,
    name: 'Appointments',
    component: Appointments,
  },
  {
    path: '/clinics/:clinicReferenceId/clinic-treatments',
    exact: true,
    name: 'Clinic treatments',
    component: ClinicTreatments,
  },
  {
    path: '/clinics/:clinicReferenceId/treatment-types',
    exact: true,
    name: 'Treatment groups',
    component: TreatmentTypes,
  },
  {
    path: '/clinics/:clinicReferenceId/clinic-treatments/:referenceId',
    exact: true,
    name: 'Clinic treatment',
    component: ClinicTreatment,
  },
  {
    path: '/clinics/:clinicReferenceId/phone-setup',
    exact: true,
    name: 'Phone setup',
    component: ClinicPhoneSetup,
  },
  { path: '/devices', exact: true, name: 'Devices', component: Devices },
  {
    path: '/devices/:deviceId',
    exact: true,
    name: 'Device',
    component: Device,
  },
  {
    path: '/devices/:deviceReferenceId/environments',
    exact: true,
    name: 'Device Environments',
    component: Environments,
  },
  { path: '/users', exact: true, name: 'Users', component: Users },
  {
    path: '/users/new',
    exact: true,
    name: 'New User',
    component: NewUser,
  },
  {
    path: '/users/:userId',
    exact: true,
    name: 'User Details',
    component: User,
  },
  {
    path: '/touchpoints',
    exact: true,
    name: 'Touchpoint',
    component: Touchpoints,
  },
  {
    path: '/touchpoints/new',
    exact: true,
    name: 'Touchpoint',
    component: Touchpoint,
  },
  {
    path: '/touchpoints/:touchpointReferenceId',
    exact: true,
    name: 'Touchpoint',
    component: Touchpoint,
  },
  {
    path: '/touchpoints-history',
    exact: true,
    name: 'Touchpoints History',
    component: TouchpointsHistory,
  },
  {
    path: '/templates/new',
    exact: true,
    name: 'Template',
    component: Template,
  },
  {
    path: '/templates/:templateReferenceId',
    exact: true,
    name: 'Template',
    component: Template,
  },
  { path: '/templates', exact: true, name: 'Templates', component: Templates },
  {
    path: '/customer-events',
    exact: true,
    name: 'Customer Events',
    component: CustomerEvents,
  },
  {
    path: '/environments',
    exact: true,
    name: 'Environments',
    component: Environments,
  },
  {
    path: '/general-treatments',
    exact: true,
    name: 'General Treatment Bundles',
    component: GeneralTreatmentBundles,
  },
  {
    path: '/general-treatments/new',
    exact: true,
    name: 'General Treatment Bundle',
    component: GeneralTreatmentBundle,
  },
  {
    path: '/general-treatments/:referenceId',
    exact: true,
    name: 'General Treatment Bundle',
    component: GeneralTreatmentBundle,
  },
  {
    path: '/general-phone-setup',
    exact: true,
    name: 'General Phone Setup',
    component: GeneralPhoneSetup,
  },
  {
    path: '/general-bundle-categories',
    exact: true,
    name: 'General Bundle Categories',
    component: GeneralBundleCategories,
  },
  {
    path: '/general-treatment-purposes',
    exact: true,
    name: 'General Treatment Purposes',
    component: GeneralTreatmentPurposes,
  },
  {
    path: '/dispacci/blacklist',
    exact: true,
    name: 'Blacklist',
    component: Blacklist,
  },

  {
    path: '/dispacci/message-logs',
    exact: true,
    name: 'Message logs',
    component: MessageLogs,
  },
  {
    path: '/general-clinic-treatments',
    exact: true,
    name: 'General Clinic Treatments',
    component: GeneralClinicTreatments,
  },
  {
    path: '/general-clinic-treatments/new',
    exact: true,
    name: 'Create General Clinic Treatment',
    component: GeneralClinicTreatment,
  },
  {
    path: '/general-clinic-treatments/:generalClinicTreatmentReferenceId',
    exact: true,
    name: 'General Clinic Treatment',
    component: GeneralClinicTreatment,
  },
  {
    path: '/faqs',
    exact: true,
    name: 'FAQs',
    component: FAQsPage,
  },
  {
    path: '/faqs/new',
    exact: true,
    name: 'Create FAQ',
    component: FAQPage,
  },
  {
    path: '/faqs/:referenceId',
    exact: true,
    name: 'Edit FAQ',
    component: FAQPage,
  },
  {
    path: '/surveys',
    exact: true,
    name: 'Surveys',
    component: SurveysPage,
  },
  {
    path: '/surveys/new',
    exact: true,
    name: 'Create PROM Survey',
    component: SurveyPage,
  },
  {
    path: '/surveys/:referenceId',
    exact: true,
    name: 'Preview Survey',
    component: SurveyPage,
  },
  {
    path: '/wondermeds/:clinicReferenceId/cluster-information',
    exact: true,
    name: 'List cluster-information',
    view: View.WONDERMEDS_CLUSTER_INFORMATION,
    component: ClusterInformationsPage,
  },
  {
    path: '/wondermeds/schemas',
    exact: true,
    name: 'List schemas',
    view: View.WONDERMEDS_SCHEMAS,
    component: SchemasPage,
  },
  {
    path: '/services',
    exact: true,
    name: 'Service monitor',
    component: ServiceMonitor,
  },
  {
    path: '/services/:service/:environment',
    exact: true,
    name: 'Service Swagger',
    component: ServiceSwagger,
  },
  {
    path: '/clinics-ops',
    exact: true,
    name: 'Clinics Operations Overview',
    component: ClinicsOperations,
  },
  {
    path: '/gap-filler-settings',
    exact: true,
    name: 'Gap Filler Settings',
    component: GapFillerSettings,
  },
  {
    path: '/recommendations',
    exact: true,
    name: 'Recommendations',
    component: Recommendations,
  },
  {
    path: '/recommendations/new',
    exact: true,
    name: 'New Recommendation',
    component: Recommendation,
  },
  {
    path: '/recommendations/:referenceId',
    exact: true,
    name: 'Recommendation',
    component: Recommendation,
  },
  {
    path: '/redesign/person-merger',
    exact: true,
    name: 'Person Merger',
    component: PersonMerger,
  },
  {
    path: '/redesign/id-finder',
    exact: true,
    name: 'Id Finder',
    component: IdFinder,
  },
  {
    path: '/redesign/universal-search',
    exact: true,
    name: 'Universal Search',
    component: UniversalSearch,
  },
]

export default routes
