import React, { ComponentType } from 'react'
import LoadingState from '../components/LoadingState'

const PAGE_REFRESHED_KEY = 'page-has-been-force-refreshed' as const

// Handles ChunkLoadError by forcing the browser to refresh when it's unable to load the component
//
// Loading lazily imported components may fail when a new version of the app is on prod, but the user has a browser opened with an old version.
// All of those components live in separate .js files with dynamically generated names ([hash].chunk.js where [hash] is generated based on the file content)
//
// Steps to reproduce:
// 1. Login into app and keep it loaded in the browser
// 2. Make any change in dynamically loaded component
// 3. Rebuild (locally) or redeploy (staging/prod)
// 4. Try to navigate to the page with changed (2.) component
// 5! Error is thrown and user is unable to perform any action (browser tries to download [hash1].chunk.js, but it's no longer there, as [hash2].chunk.js was generated and the former was deleted)
//
// Solution: Force browser to reload whenever (5.) happens - so new index.html is fetched which loads updated js files
// (notice: index.html cannot be cached)
const lazyWithRetry = <T extends ComponentType<any>>(componentImport: () => Promise<{ default: T }>) =>
  React.lazy<any>(async () => {
    try {
      const component = await componentImport()

      localStorage.setItem(PAGE_REFRESHED_KEY, 'false')

      return component
    } catch (error) {
      const pageHasAlreadyBeenForceRefreshed = JSON.parse(localStorage.getItem(PAGE_REFRESHED_KEY) || 'false')

      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        localStorage.setItem(PAGE_REFRESHED_KEY, 'true')
        window.location.reload()
        // Just to ignore react/ts errors, as location.reload isn't immediate
        return { default: LoadingState }
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error
    }
  })

export default lazyWithRetry
