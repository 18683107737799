import React, { useEffect } from 'react'
import { useCredentials } from '@dentalux/security'
import posthog from 'posthog-js'

interface Props {
  apiKey?: string
  apiHost?: string
  children?: React.ReactNode
}

const PosthogProvider = ({ apiKey, apiHost, children }: Props) => {
  const user = useCredentials()?.authentication

  useEffect(() => {
    if (!apiKey?.trim?.()) return

    posthog.init(apiKey, { api_host: apiHost, persistence: 'localStorage' })

    if (user?.referenceId && user?.email) {
      const { referenceId, email } = user
      posthog.identify(referenceId, {}, { email, referenceId })
    }
  }, [apiKey, apiHost, user])

  return <>{children}</>
}

export default PosthogProvider
