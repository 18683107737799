import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  Collapse,
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import clsx from 'clsx'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { matchPath, useLocation } from 'react-router-dom'
import { View } from '../types'
import useUserAccess from '../hooks/useUserAccess'

export const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaper: {
    width: drawerWidth,

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menu: {
    padding: theme.spacing(2),
  },
  menuItem: {
    borderRadius: 5,
  },
  menuItemIcon: {
    minWidth: 30,
    marginRight: 8,
  },
  menuItemText: {
    fontSize: '0.9rem',
  },
  menuIcon: {
    fontSize: '1rem',
    width: '2rem',
  },
  collapse: {
    '& $menuItem': {
      paddingLeft: theme.spacing(4),
    },
  },
  subheader: {
    position: 'relative',
    fontSize: '0.7rem',
    textTransform: 'uppercase',
    marginTop: theme.spacing(1),
  },
  drawerContainer: {
    overflow: 'hidden',

    '&:hover': {
      overflow: 'auto',
    },
  },
  drawerShift: {
    marginLeft: -drawerWidth,

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}))

export type NavItem = {
  name: string
  url?: string
  icon?: string
  title?: boolean
  children?: NavItem[]
  id?: string
  view?: View
}

interface Props {
  isOpen: boolean
  navItems: NavItem[]
}

const AppDrawer = ({ navItems, isOpen }: Props) => {
  const [collapseState, setCollapseState] = useState<Record<string, boolean>>({})
  const ref = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const location = useLocation()

  const { hasAccessToTheView } = useUserAccess()

  const idMapping = useMemo(() => {
    return navItems
      .filter((i) => i.id)
      .reduce<{ [id: string]: string }>((acc, val) => {
        return {
          ...acc,
          [val.id || '']: val.name,
        }
      }, {})
  }, [navItems])

  useEffect(() => {
    const match = matchPath<{ id: string }>(location.pathname, { path: '/clinics/:id', exact: true })
    if (!match) {
      return
    }
    setCollapseState((value) => ({
      ...value,
      [idMapping[match.params.id]]: true,
    }))

    setTimeout(() => {
      const elem = ref.current?.querySelector(`a[href="/clinics/${match.params.id}"]`)
      elem?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }, 0)
  }, [location, idMapping])

  const handleMenuItemDropdownClick = useCallback(
    (name: string) => (event: React.MouseEvent) => {
      event.preventDefault()

      const previousValue = Boolean(collapseState[name])

      setCollapseState({
        ...collapseState,
        [name]: !previousValue,
      })
    },
    [setCollapseState, collapseState]
  )

  const renderNavItem = useCallback(
    (navItem: NavItem, index: number) => {
      const isOpen = collapseState[navItem.name]
      const hasAccessToView = hasAccessToTheView(navItem.view)

      if (!hasAccessToView) return null

      if (navItem.title) {
        return (
          <ListSubheader key={index} className={classes.subheader}>
            {navItem.name}
          </ListSubheader>
        )
      }
      return (
        <div key={index}>
          <ListItem
            component="a"
            className={classes.menuItem}
            onClick={navItem.children ? handleMenuItemDropdownClick(navItem.name) : undefined}
            href={navItem.url}
            disableRipple
            button
          >
            <ListItemIcon className={classes.menuItemIcon}>
              {navItem.icon && <Icon className={clsx(navItem.icon, classes.menuIcon)} />}
            </ListItemIcon>

            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              classes={{ primary: classes.menuItemText }}
              primary={navItem.name}
            />

            {navItem.children && <>{isOpen ? <ExpandLess /> : <ExpandMore />}</>}
          </ListItem>

          {navItem.children && (
            <Collapse className={classes.collapse} in={isOpen}>
              {navItem.children.map(renderNavItem)}
            </Collapse>
          )}
        </div>
      )
    },
    [classes, collapseState, handleMenuItemDropdownClick]
  )

  return (
    <Drawer
      ref={ref}
      classes={{
        paper: clsx(classes.drawerPaper, { [classes.drawerShift]: !isOpen }),
      }}
      className={clsx(classes.drawer, {
        [classes.drawerShift]: !isOpen,
      })}
      variant="permanent"
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List className={classes.menu}>{navItems.map(renderNavItem)}</List>
      </div>
    </Drawer>
  )
}

export default AppDrawer
