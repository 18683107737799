import React from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { LoginPage, ProtectedRoute, SecurityProvider } from '@dentalux/security'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material'
import NiceModal from '@ebay/nice-modal-react'

import history from './services/history'
import theme from './theme'

import './App.scss'

import AppShell from './components/AppShell'
import LoadingState from './components/LoadingState'
import PosthogProvider from './providers/PosthogProvider'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useTranslations } from './hooks/useTranslations'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const reactQueryConfig = {
  refetchAllOnWindowFocus: false,
  refetchOnWindowFocus: false,
  /**
   * We are disabling retry when we get 403 or 401
   * this way we will get visible feedback faster
   * and will save some api calls.
   * Also, we do not want to retry on 504 (Gateway Timeout)
   * - it may unnecessarily drain backend resources.
   * Otherwise, we allow 2 retries.
   *
   */
  retry: (numOfRetries: number, error: any) => {
    const httpStatus = error.response?.status ?? -1
    if (httpStatus === 403 || httpStatus === 401 || httpStatus === 504) return false
    return numOfRetries < 2
  },
}

const queryClient = new QueryClient({ defaultOptions: { queries: reactQueryConfig, mutations: reactQueryConfig } })

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <NiceModal.Provider>
              <Router history={history}>
                <SecurityProvider
                  config={{
                    loginPath: '/login',
                    env: window.env.REACT_APP_HADES_ENV as 'staging' | 'production',
                    loading: <LoadingState />,
                    enableImpersonation: true,
                  }}
                >
                  <PosthogProvider
                    apiKey={window.env.REACT_APP_POSTHOG_API_KEY}
                    apiHost={window.env.REACT_APP_POSTHOG_API_HOST}
                  >
                    <React.Suspense fallback={<LoadingState />}>
                      <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={true}
                        closeOnClick
                        draggable
                        pauseOnHover
                      />

                      <Switch>
                        <Route exact path="/login">
                          <LoginPage title="Jarvis" />
                        </Route>

                        <ProtectedRoute path="/">
                          <Route path="/" render={(props) => <AppShell {...props} name="Home" />} />
                        </ProtectedRoute>
                      </Switch>
                    </React.Suspense>
                  </PosthogProvider>
                </SecurityProvider>
              </Router>
            </NiceModal.Provider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  )
}

export default App
