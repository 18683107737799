import { clinicClient, coreJarvisClient, dispacciClient, itOpsClient } from '.'
import { AxiosPromise } from 'axios'
import {
  Clinic,
  ClinicForm,
  ClinicItOps,
  ClinicMaxMessageRate,
  ClinicSoftwareType,
  ClinicType,
  Device,
  HealthStatus,
  MonitoringSubState,
} from '../../types'
import { getDevices } from './devices'

export interface ClinicsFilters {
  name?: string
  clinicType?: ClinicType
  softwareType?: ClinicSoftwareType
  deprecated?: boolean
  healthStatus?: HealthStatus
  monitoringState?: MonitoringSubState
}

const IS_CLINIC_SERVICE_ENABLED = true

const version = IS_CLINIC_SERVICE_ENABLED ? 'v1' : 'v3'
const client = IS_CLINIC_SERVICE_ENABLED ? clinicClient : coreJarvisClient

export const getClinics = async (filters: ClinicsFilters = { deprecated: false }): Promise<Clinic[]> => {
  const { data } = await client.get(`/${version}/clinics`, { params: filters })

  return IS_CLINIC_SERVICE_ENABLED ? data.content : data
}

export const getClinicsAndDevices = async (filters: ClinicsFilters = { deprecated: false }): Promise<Clinic[]> => {
  const clinics = await getClinics(filters)

  try {
    const devices = await getDevices()
    devices.forEach((device: Device) => {
      const givenClinicId = device?.clinicIds?.[0]
      if (givenClinicId) {
        clinics.forEach((clinic: Clinic) => {
          if (clinic.referenceId === givenClinicId) {
            clinic.deviceIds = clinic.deviceIds || []
            clinic.deviceIds.push(device.id)
          }
        })
      }
    })
  } catch (e) {
    // let's skip then the environment variables for a moment and simply raise a warning
  }

  return clinics
}

export const getClinic = async (clinicReferenceId: string): Promise<Clinic> => {
  const { data } = await client.get(`/${version}/clinics/${clinicReferenceId}`)

  return data
}

export const putClinic = ({
  clinicReferenceId,
  data,
}: {
  clinicReferenceId: string
  data: ClinicForm
}): AxiosPromise<Clinic> => {
  return client.put(`/${version}/clinics/${clinicReferenceId}`, data)
}

export const getClinicMaxMessageRate = async (clinicReferenceId: string): Promise<ClinicMaxMessageRate> => {
  const { data } = await dispacciClient.get(`/admin/clinic/${clinicReferenceId}/message-rate`)

  return { maxMessageRate: data }
}
export const putClinicMaxMessageRate = ({
  clinicReferenceId,
  data,
}: {
  clinicReferenceId: string
  data: ClinicMaxMessageRate
}): AxiosPromise<Clinic> => {
  return dispacciClient.put(`/admin/clinic/${clinicReferenceId}/message-rate`, data)
}

export const postClinic = ({ data }: { data: ClinicForm }): AxiosPromise<Clinic> => {
  return client.post(`/${version}/clinics`, data)
}

export const getClinicsItOps = async (filters: ClinicsFilters = { deprecated: false }): Promise<ClinicItOps[]> => {
  const { data } = await itOpsClient.get('/clinics/it-ops', {
    params: filters,
  })

  return data
}
