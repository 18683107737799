import * as contentful from 'contentful'
import _set from 'lodash/set'

type Application = {
  name: string
  slug: string
  contentTypeId: string
  fields: any
}

type Translation = {
  application: contentful.Entry<Application>
  key: string
  translation: string
  contentTypeId: string
  fields: any
}

export type TranslationsJSON = Record<string, string | Record<string, unknown>>

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID || '',
  accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY || '',
})

const toTranslationObject = (items: contentful.EntryCollection<Translation>['items']): TranslationsJSON => {
  return items.reduce<TranslationsJSON>((acc, item) => {
    return _set({ ...acc }, (item.fields as any).key, item.fields.translation)
  }, {})
}

export type GetTranslationsReturn = {
  en: TranslationsJSON
  de: TranslationsJSON
}

export const getTranslations = async (): Promise<GetTranslationsReturn> => {
  const pageSize = 1000

  const defaultQuery = {
    content_type: 'translation',
    'sys.contentType.sys.id': 'translation',
    'fields.application.sys.contentType.sys.id': 'application',
    'fields.application.fields.slug[in]': `common,jarvis,availy`,
    limit: pageSize,
  }

  const enTranslations: contentful.EntryCollection<Translation>['items'] = []
  const deTranslations: contentful.EntryCollection<Translation>['items'] = []

  let skip = 0

  while (skip <= pageSize * 10) {
    const dePromise = client.getEntries<Translation>({ ...defaultQuery, skip, locale: 'de-DE' })
    const enPromise = client.getEntries<Translation>({ ...defaultQuery, skip, locale: 'en-US' })

    const [de, en] = await Promise.all([dePromise, enPromise])

    enTranslations.push(...en.items)
    deTranslations.push(...de.items)

    if (de.total < skip + pageSize && en.total < skip + pageSize) {
      break
    }
    skip += pageSize
  }

  return { de: toTranslationObject(deTranslations), en: toTranslationObject(enTranslations) }
}
