import React from 'react'

import { AppBar as MaterialAppBar, IconButton, Toolbar, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import MenuIcon from '@mui/icons-material/Menu'

import { ReactComponent as Logo } from '../assets/logo.svg'
import UserDropdown from './UserDropdown'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.grey[900],
    boxShadow: 'none',
    background: 'white',
    justifyContent: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
  },
  production: {
    background: '#00624f',
    color: 'white',
    border: 'none',
  },
  staging: {
    background: 'white',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoWrapper: {
    display: 'inline-flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  logo: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

const env = window.env.REACT_APP_ENV as 'staging' | 'production'
const isStaging = env === 'staging'

interface Props {
  onToggleDrawer: () => any
}

const AppBar = ({ onToggleDrawer }: Props) => {
  const classes = useStyles()

  return (
    <MaterialAppBar className={clsx(classes.appBar, classes[env])} position="fixed">
      <Toolbar className={classes.toolbar}>
        <IconButton onClick={onToggleDrawer} edge="start" color="inherit" aria-label="menu" size="large">
          <MenuIcon />
        </IconButton>

        <div className={classes.logoWrapper}>
          <Logo className={classes.logo} />
          <Typography variant="h6">
            Jarvis {isStaging && <span style={{ fontSize: '11px' }}>(staging)</span>}
          </Typography>
        </div>

        <UserDropdown />
      </Toolbar>
    </MaterialAppBar>
  )
}

export default AppBar
